.mr-btn-primary {
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 1, 0.25);
    background-image: linear-gradient(358deg, #5e00ff, #5f98ec);
    color: #ffffff;
}

.mr-btn-primary::after {
    background-image: url('../../assets/images/arrow-right.svg');
    background-color: rgba(0, 0, 0, 0.1);
}

.mr-btn-secondary {
    background-color: #ffffff;
}

.mr-btn-secondary::after {
    background-image: url('../../assets/images/settings.svg');
}

.mr-btn-inactive,
.mr-btn-primary,
.mr-btn-secondary,
.mr-btn-normal,
.mr-btn-create,
.mr-btn-refresh,
.buttondd,
.mr-btn-delete ,
.mr-btn-cart,
.mr-btn-sync,
.mr-btn-back ,
.mr-btn-export,
.mr-btn-import,
.mr-btn-view {
    cursor: pointer;
    font-family: OpenSans-SemiBold,sans-serif;
    font-size: 12px;
    border-radius: 25px;
    min-width: 120px;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 1px 12px;
    margin: 0 3px;
}

.mr-btn-inactive::after,
.mr-btn-primary::after,
.mr-btn-secondary::after,
.mr-btn-normal::after,
.mr-btn-create::after ,
.mr-btn-refresh::after,
.mr-btn-delete::after,
.mr-btn-cart::after,
.mr-btn-sync::after,
.mr-btn-back::after,
.mr-btn-export::after,
.mr-btn-import::after ,
.mr-btn-view::after,
.buttondd::after{
    display: inline-block;
    text-align: center;
    content: '';
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    background-size: 16px;
    background-position: center;
    border-radius: 50%;
    margin-left: 10px;
}


.mr-btn-primary {
    border-top: none;
}

.mr-btn-inactive {
    background-color: rgba(60, 69, 88, 0.4);
    background-image: none;
    box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14);
    border: none;
    color: #ffffff;
    cursor: not-allowed;
}

.mr-btn-inactive::after {
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url('../../assets/images/arrow-right.svg');
}

.mr-btn-normal {
    background-color: #ffffff;
    padding-right: 12px;
    -ms-flex-pack: center;
    justify-content: center;
    height: 34px;
}

.mr-btn-normal::after {
    display: none;
}

.mr-btn-create ,
.mr-btn-refresh,
.mr-btn-delete,
.mr-btn-cart ,
.mr-btn-sync ,
.mr-btn-back,
.mr-btn-export,
.mr-btn-import,
.mr-btn-view,
.buttondd {
    padding-bottom: 0;
}

.mr-btn-create::after,
.mr-btn-refresh::after,
.mr-btn-delete::after,
.mr-btn-cart::after,
.mr-btn-sync::after,
.mr-btn-back:after,
.mr-btn-export::after,
.mr-btn-import::after,
.mr-btn-view::after,
.buttondd::after {
    content: '+';
    color: #ffffff;
    background-size: auto;
    font-size: 25px;
}
.mr-btn-sync::after{
    font-family: FontAwesome;
    content: "\f014";
}
.mr-btn-create::after{
    content: '+';
}

.buttondd::after{
    content:"\f078";
    font-family: FontAwesome;
    display: inline-block;
    vertical-align: middle;
    padding-top:5px;
    font-size:18px;
    margin-left:10px;
}

.buttondd li{font-size:13px;}

.mr-btn-delete::after {
    font-family: FontAwesome;
    content: "\f014";
    display: inline-block;
    vertical-align: middle;
    padding-top:2px;
    font-size:21px;
}

.mr-btn-refresh::after {
    font-family: FontAwesome;
    content: "\f021";
    display: inline-block;
    vertical-align: middle;
    padding-top:2px;
    font-size:21px;
}

.mr-btn-cart::after{
    content: "\f07a";
    font-family: FontAwesome;
    font-size:21px;
}
.mr-btn-sync::after{
    content: "\f021";
    font-family: FontAwesome;
    font-size:20px;
    padding-top:3px;
}

.mr-btn-back::after{
    content: "\f100";
    font-family: FontAwesome;
    font-size:26px;
}

.mr-btn-export::after{
    content: "\f093";
    font-family: FontAwesome;
    font-size:17px;
    padding-left:4px;
    padding-top:5px;
}

.mr-btn-import::after{
    content: "\f019";
    font-family: FontAwesome;
    font-size:17px;
    padding-left:4px;
    padding-top:5px;
}

.mr-btn-view::after{
    content: "\f06e";
    font-family: FontAwesome;
    font-size:17px;
    padding-left:4px;
    padding-top:5px;
}

.mr-btn-tertiary {
    border-radius: 5px;
    border: 1px solid rgba(60, 69, 88, 0.1);
    font-family: OpenSans-Semibold,sans-serif;
    font-size: 13px;
    color: #ffffff;
    padding: 7px 12px;
    margin: 0 3px;
    min-width: 80px;
    text-align: center;
}

.mr-btn-tertiary.btn-active {
    background-color: #828dff;
}

.mr-btn-tertiary.btn-inactive {
    background-color: rgba(60, 69, 88, 0.3);
    cursor: not-allowed;
}