figure {
  margin: 0;
}

.card {
  border-radius: 0;
}

.breadcrumb {
  display: inline-block;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 15px;

  li {
    float: left
  }
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font: 400 24px/18px "OpenSans-Regular", sans-serif
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.input-group:not(.has-validation)>.form-control:not(:last-child),
.input-group:not(.has-validation)>.custom-select:not(:last-child),
.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after {
  border-radius: 4px 0 0 4px;
}

/* a.boxlink:hover .db_box{
    background:$base-clr;
} */

.inputc-error .error-mge,
.error-mge,
.with-errors,
.errors {
  bottom: -17px;
}

.accordion_c {
  .card {
    border-radius: 4px;
    margin-bottom: 2px;
  }

  .innrcaption {
    font-size: 15px !important;
    margin: 8px 0 0 5px !important
  }
}


.inputc {
  display: flex;
}

/* mat field */

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mr-btn-back::after {
  padding-top: 7px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.mat-form-field {
  width: 100% !important;
}

.mat-form-field-infix {
  width: 100% !important;
  font-family: OpenSans-Regular;
  font-size: 13px;
  border-radius: 4px;
  padding: 7px 15px 8px 15px !important;
  box-shadow: none;
  transition: none;
  border: 1px solid $input-bdr;
  color: $input-txt;
  background: $input-bg;
  height: 36px;
}

.mat-small {
  .mat-form-field-infix {
    padding: 4px 12px;
    height: 29px;
  }

  .mat-form-field {
    line-height: .8;
  }

  .mat-select-arrow {
    margin-top: 10px;
  }
}

.mat-form-field-type-mat-select .mat-form-field-label {
  padding: 12px 15px 0 15px;
}

.mat-select-arrow {
  margin-top: 15px !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  display: none;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-select-panel .mat-option:hover {
  background: $base-clr;
  color: $white;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $white;
}

.tree_drop {
  background: $reverse-clr
}

.tree_drop li:before,
.tree_drop li ul li:before,
.tree_drop li ul li ul li:before {
  color: $base-clr;
}

.headbtnc button a,
.headbtnc button a:hover {
  color: $link-clr;
  text-decoration: none;
}

.modal-header {
  border-bottom: 0;
}

/* mat accordion */
.mat-expansion-panel{
  box-shadow:none !important;
}

.mat-expansion-panel-header.mat-expanded
{
  height:auto;
  min-height:24px;
  padding-top:10px;
  padding-bottom:10px;
}

.mat-expansion-panel-header{
  height:auto;
}

.mat-expansion-panel {
  padding-top:7px;
  padding-bottom:7px;
}

.mat-expansion-panel-header{
  padding-bottom:10px;
}

.mat-expansion-panel-body{
  padding-bottom:10px !important;
}

.mat-expansion-panel-header{
  padding:0 14px;
}

.mat-accordion .mat-expansion-panel:last-of-type{
  border-bottom-left-radius:0px !important;
  border-bottom-right-radius:0px !important;
}

.matacco_ec {
  .mat-accordion:last-of-type .mat-expansion-panel{
    border-bottom-left-radius:4px !important;
    border-bottom-right-radius:4px !important;
  }
}

.mat-accordion .mat-expansion-panel:first-of-type{
  border-top-left-radius:0px !important;
  border-top-right-radius:0px !important;
}

.matacco_ec {
  .mat-accordion:first-of-type .mat-expansion-panel{
    border-top-left-radius:4px !important;
    border-top-right-radius:4px !important;
  }
}

.mat-expansion-panel-header-title i{
  color:$base-clr;
}

.mat-expansion-panel-header:hover{
  background:#fff !important;
}

/* button styles */
/* .mr-btn-back:after{padding-top:7px} */

.mat-option.mr-4 {
  margin-right: 0 !important;
}

.nav-tabs {
  background: $lightdark-clr;
  border-radius: 4px 4px 0 0;
}

.nav-tabs {
  background: none;
}

.radioc .checkmark-r {
  top: 1px;
}

.copyrightc img {
  width: 21px;
  display: inline-block;
  padding: 0 4px;
}

.ng-select .ng-select-container {
  height: 36px;
  border-radius: 4px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-dropdown-panel {
  border: 1px solid #ccc;
}

.eighteenpl,
.datatree li {
  padding-left: 18px;
}

li.selectli,
li.selectTableli {
  margin-left: 18px;
}

li.selectTableli,
li.selectTableli {
  margin-left: 18px;
}

li.selectTableli.active span{
  padding-left:3px;
}

.modal-backdrop.show {
  display: none
}

.db_box:hover .cardcount {
  text-decoration: none;
}

.progresstd {
  width: 110px
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 8px;
  line-height: 1.5;
}

.icons-container i {
  font-size: .9rem;
}

.modal-title {
  font-size: 1.4rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0 !important;
}

.close_btn_page {
  padding-top: 27px
}


.loader_top_adj {
  min-height: 200px;

  .loaderico {
    top: 60px;
  }
}

.swal2-html-container {
  text-align: center;
  font-size: 14px !important;
  color: #7b7b7b !important;
}

.swal2-title {
  text-align: center;
}

.swal2-actions {
  min-width: 300px;
}

.user_management_ico {
  position: relative
}

.user_management_ico:after {
  font-family: FontAwesome;
  position: absolute;
  top: 6px;
  left: 10px;
  font-size: .6rem;
  content: "\f013";
  border-radius: 50%;
}

.innerbox-imgc {
  margin: 15px 0
}

/* inside page box content */
.innerbox_title {
  font-size: 16px !important;
  font-family: OpenSans-SemiBold !important;
  margin-bottom: 2px !important;
}

.innerbox_text {
  font-size: 14px !important;
}

li.selectTableli.active,
li.selectTableli.active:hover{
  background:$btn_clr;
  color:$white;
}

li.selectTableli:before.active{
  color:$white;
}

.selectTableli:before{
  color:reverse-clr;
}

li.selectTableli:hover{
    background:$lightdark-clr;
    color: $hover-clr
}

a.boxlink:hover {
  .overview-card-wrapper{
    background:#006e74;
  }
}

/* themes */
.grey_theme {

  a.boxlink:hover {
    .overview-card-wrapper{
      background:#fdb913;
    }
  }

  .mat-form-field-infix {
    border: 1px solid $g_input-bdr;
    color: $g_input-txt;
    background: $g_input-bg;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .mat-select-panel .mat-option:hover {
    background: $g_base-clr;
    color: $white;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $white;
  }


  a.boxlink:hover .db_box {
    background: $g_base-clr;
  }

  a.boxlink:hover .cardico,
  a.boxlink:hover .cardcount,
  a.boxlink:hover .cardtitle {
    color: $g_reverse-clr !important;
  }

  .tree_drop {
    background: $g_odd-clr;
  }

  .tree_drop li:before,
  .tree_drop li ul li:before,
  .tree_drop li ul li ul li:before {
    color: $g_yellow_txt;
  }

  .headbtnc button a {
    color: $white;
  }

  li.selectTableli.active,
  li.selectTableli.active:hover{
    background: $g_text-med-clr;
    color:$white;
  }

  li.selectTableli.active.active{
    color:$white;
  }

  .selectTableli:before{
    color: $g_yellow_txt;
  }

  li.selectTableli:hover{
      background:$g_lightdark-clr;
      color: $g_hover-clr
  }

  .mat-expansion-panel{
    background:$g_btn_clr;
  }

  .mat-expansion-panel-header:hover{
    background:$g_btn_clr !important;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description{
    color:$white;
  }

  .mat-expansion-panel-header-title i{
    color:$g_grey_btn_hvr;
  }

}

.red_theme {

  .blue_theme {
    a.boxlink:hover {
      .overview-card-wrapper{
        background:#3A97D3;
      }
    }
  }
  
  a.boxlink:hover {
    .overview-card-wrapper{
      background:#f74d4d;
    }
  }
  
  .mat-form-field-infix {
    border: 1px solid $r_input-bdr;
    color: $r_input-txt;
    background: $r_input-bg;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .mat-select-panel .mat-option:hover {
    background: $r_base-clr;
    color: $white;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $white;
  }

  a.boxlink:hover .db_box {
    background: $r_base-clr;
  }

  .tree_drop {
    background: $r_reverse-clr
  }

  .tree_drop li:before,
  .tree_drop li ul li:before,
  .tree_drop li ul li ul li:before {
    color: $r_base-clr;
  }

  .headbtnc button a {
    color: $r_link-clr
  }

  

  li.selectTableli.active,
  li.selectTableli.active:hover{
    background:$r_btn_clr;
    color:$white;
  }

  li.selectTableli.active:before{
    color:$white;
  }

  .selectTableli:before{
    color:$r_reverse-clr;
  }

  li.selectTableli:hover{
      background:$r_lightdark-clr;
      color: $r_hover-clr
  }
  .mat-expansion-panel-header-title i{
    color:$r_base-clr;
  }
}

.blue_theme {

  
  a.boxlink:hover {
    .overview-card-wrapper{
      background:#3A97D3;
    }
  }
  
  
  .mat-form-field-infix {
    border: 1px solid $b_input-bdr;
    color: $b_input-txt;
    background: $b_input-bg;
  }

  .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
  .mat-select-panel .mat-option:hover {
    background: $b_base-clr;
    color: $white;
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $white;
  }

  a.boxlink:hover .db_box {
    background: $b_base-clr;
  }

  .tree_drop {
    background: $b_reverse-clr
  }

  .tree_drop li:before,
  .tree_drop li ul li:before,
  .tree_drop li ul li ul li:before {
    color: $b_base-clr;
  }

  .headbtnc button a {
    color: $b_link-clr
  }

  li.selectTableli.active,
  li.selectTableli.active:hover{
    background:$b_btn_clr;
    color:$white;
  }

  li.selectTableli.active:before{
    color:$white;
  }

  .selectTableli:before{
    color:$b_base-clr;
  }
  
  li.selectTableli:hover{
      background:$b_lightdark-clr;
      color: $b_hover-clr
  }
  .mat-expansion-panel-header-title i{
    color:$b_base-clr;
  }
}

.collapsebtn.small {
  display: none
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  body {
    font-size: 1rem;
  }
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
  .db_boxc {
    width: 33.3%
  }

  .sidebar-wrapper,
  .collapsebtn.large,
  .ico-wt-txt,
  .adjusticons .ico-wt-txt {
    display: none;
  }

  .ico-wt-txt,
  .adjusticons .ico-wtno-txt,
  .page-ec.showsidebar .sidebar-wrapper {
    display: block;
  }

  .collapsebtn.small {
    display: block;
  }

  .expandmode .pagec,
  .pagec {
    margin-left: 0;
  }

  .header-component .mr-header-brand-bg {
    width: 48px !important;
  }

  .sidebar-wrapper {
    border: 1px solid $td-bdr;
  }
  


  /* grey theme */
  .grey_theme {
    .sidebar-wrapper {
      border: 1px solid $g_td-bdr;
    }
    
  }
  /* red theme */
  .red_theme {
    .sidebar-wrapper {
      border: 1px solid $r_td-bdr;
    }

  }

  /* blue theme */
  .blue_theme {
    .sidebar-wrapper {
      border: 1px solid $b_td-bdr;
    }

  }
}


// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .inputc {
    display: block
  }

  .fieldc:last-child {
    padding-left: 0
  }

  .inputc .fieldc:first-child {
    padding-right: 0;
    padding-bottom: 10px;
  }

  .db_boxc {
    width: 50%
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .db_boxc {
    width: 100%
  }
}
