body{margin:auto}
th{
    font-weight:normal !important ;
}

.icons-container img, .icons-container i{
    font-size:1rem;
}

.card{
    border-radius:0;
}

.breadcrumb {
    display: inline-block;
    flex-wrap:nowrap; 
    padding: 0;
    margin-bottom: 0; 
    li{float:left}
}

/* ngb dropdown *** right button */
.ngbdd_right_c{display:inline-block}

.ngbdd_right.dropdown-toggle{
    padding:0;
    border:none;
    background:none;
    display:inline-block;
}

.ngbdd_right .buttondd{
    display:inline-block;
}

.dropup .ngbdd_right.dropdown-toggle::after,
.ngbdd_right.dropdown-toggle::after{
    display:none;
}

.ngbdd_right_c .dropdown-item{
    white-space:initial;
    line-height:1.1;
}

/* input container */
.inputc{
    display:flex;
}

.checkmark-c,
.checkmark-r{top:4px}

.nextbtn-input{
    height:36px;
}

/* step */
.steptitle{
    font-size:17px;
}

.logoc{
    padding:10px 0 0 10px;
}

.copyrightc img{
    width:21px;
    display:inline-block;
    padding:0 4px;
}

/* rounded corner icon with notification */
.notificationc{
    padding:2px 0 0 7px;
    font-size:14px;
    height:30px;
    width:30px;
    margin-right:10px;
    margin-top:8px;
    cursor:pointer;
}

.notify_count{
    position:absolute;
    top:-6px;
    right:-7px;
    padding:2px 4px;
    line-height:1;
    border-radius:8px;
    font-size:12px;
    color:#fff;
}

.canvasBtns{
    top:0
}

a.boxlink.nohover:hover{
    text-decoration:none !important;
}

/* box with large image */

.adminbox{
    text-align:center;
    .adminbox-imgc{
        text-align:center;
        margin:15px 0;
        height:120px;
        max-width:98%;
        background-position: center; 
        background-repeat: no-repeat; 
    }
    .adminbox-title {
        font-size:16px;
        font-family:"OpenSans-Bold";
        margin-bottom:2px;
    }
    .adminbox-text{
        font-size:14px;
    }
    i{
        font-size:72px;
        margin-top:30px;
    }
}

a.boxlink:hover {
    background:#f00;
    .adminbox-title, .adminbox-text{
        color:#fff;
    }
}

/* multiple font awesomeicon*/
.multipleicon_c {
  position: relative
}

.second_icon:before {
    position: absolute;
    bottom: -8px;
    right: -17px;
    z-index: 0;
    font-size: 36px;
    border-radius: 50%;
    padding: 7px;
    background: #006e74;
    color: #fff;
    border:4px solid #fff;
}

a.boxlink:hover .second_icon:before {
    border:4px solid #006e74;
    background: #fff;
    color: #006e74;
}

.top_btn {
    cursor: pointer;
    font-size: 12px;
    border-radius: 25px;
    min-height:38px;
    min-width: 120px;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 40px 1px 12px;
    margin: 0 3px;
    position:relative;
    background:$reverse-clr;
    border:1px solid $base-clr;
    color:$base-clr;
    &:focus {
        box-shadow: 0px 0px 0px 2px $base-clr inset !important;
    }
    .top_btn_icon{
        display: inline-block;
        text-align: center;
        content: '';
        background-repeat: no-repeat;
        width: 36px;
        background-size: 16px;
        background-position: center;
        border-radius: 50%;
        margin-left: 10px;
        position:absolute;
        right:0;
        bottom:-1px;
        top:-1px;
        font-size:16px;
        padding-top:9px;
        background: #006e74;
        color: #fff;
    }
    .top_second_icon{
        position: absolute;
        bottom: 9px;
        right: 2px;
        border-radius: 50%;
        color: #006e74;
        background:#fff;
        border:1px solid #006e74;
        padding: 2px;
        font-size: 9px;
        width:16px;
        height:16px;
        text-align:center
    } 
}

.multiicon_c{
    position:relative;
    &.btm_icon i{
        font-size:13px;
    }
    .inner_icon.bottom,
    .inner_icon.left,
    .inner_icon-round:before{
        position:absolute;
        height: auto;
        width: auto;
        margin-right: 0px;
        float:none;
    }
    .inner_icon.left{
        top:2px;
        left:10px;
    }
    .inner_icon.bottom{
        bottom:-8px;
        left: -2px;
    }
    .inner_icon-round:before{
        bottom: -8px;
        left: 8px;
        line-height:1.4;
        font-size:10px;
        z-index: 0;
        border-radius: 50%;
        padding: 2px;
        background: #333;
        color: #fff;
        border:1px solid #fff;
    }
}

.multiicon_c:hover i{color: $hover-clr}
.multiicon_c:hover .inner_icon-round:before{background: $hover-clr}

.breadcrumb {
    .multiicon_c:hover i{
        color:#777;
    }
    .multiicon_c:hover .inner_icon-round:before,
    .multiicon_c .inner_icon-round:before{
        bottom:1px;
        left:4px;
        line-height:1;
        font-size:8px;
        padding:1px;
        background:#777;
        border:1px solid #fff;
        color:#fff;
    }
    a .multiicon_c:hover i{
        color:#006e74;
    }
    a .multiicon_c .inner_icon-round:before,
    a .multiicon_c:hover .inner_icon-round:before{
        background:#006e74;
        color:#fff;
    }  
} 

/* color code */
.grey_theme {
    .second_icon:before {
        border:4px solid $g_reverse-clr;
        background: $g_yellow_txt;
        color: $g_reverse-clr;
    }

    a.boxlink:hover .second_icon:before {
        background: $g_reverse-clr;
        color: $g_yellow_txt;
        border:4px solid $g_yellow_txt;
    }

    .top_btn {
        background: $g_bcrum_btn_bg;
        border:1px solid $g_bcrum_btn_bdr;
        color:$white;
        &:focus {
            box-shadow: 0px 0px 0px 2px $base-clr inset !important;
        }
        &:hover{
            background:$g_hover-clr;
        }
        &:hover .top_btn_icon{
            background:$white;
            
        }
        .top_btn_icon{
            background:$g_secondry-clr;
            color:$g_base-clr;
        }
        .top_second_icon{
            background: $g_base-clr;
            border:1px solid $g_secondry-clr;
            color:$white;
        }
    }

    .multiicon_c{
        .inner_icon-round:before{
            background: $g_base-clr;
            border:1px solid $g_secondry-clr;
            color:$white;
        }
    }
    
    .multiicon_c:hover i{color: $g_hover-clr}
    .multiicon_c:hover .inner_icon-round:before{background: $g_hover-clr}

    .breadcrumb {
        .multiicon_c:hover i{
            color:#fff;
        }
        .multiicon_c:hover .inner_icon-round:before,
        .multiicon_c .inner_icon-round:before{
            background:#fff;
            border:1px solid $g_odd-clr;
            color:$g_odd-clr;
        }
        a .multiicon_c:hover i{
            color:$g_hover-clr;
        }
        a .multiicon_c .inner_icon-round:before,
        a .multiicon_c:hover .inner_icon-round:before{
            background:$g_hover-clr;
            color:$g_odd-clr;
        }  
    } 
}


.red_theme {
        .second_icon:before {
            background: $r_base-clr;
            color: $white;
        }
        
        a.boxlink:hover .second_icon:before {
            background: $white;
            color: $r_base-clr;
            border:4px solid $r_base-clr;
        }

        .top_btn {
            color:$r_base-clr;
            border:1px solid $r_base-clr;
            &:focus {
                box-shadow: 0px 0px 0px 2px $r_base-clr inset !important;
            }
            &:hover .top_btn_icon{
                background:$r_btn_clr;
            }
            .top_btn_icon{
                background:$r_btn_clr;
            }
            .top_second_icon{
                background: $white;
                color: $r_base-clr;
                border:1px solid $r_base-clr;
            }
        }
        .multiicon_c:hover i{color: $r_hover-clr}
        .multiicon_c:hover .inner_icon-round:before{background: $r_hover-clr}

        .breadcrumb {
            .multiicon_c:hover i{
                color:#777;
            }
            .multiicon_c:hover .inner_icon-round:before,
            .multiicon_c .inner_icon-round:before{
                background:#777;
                border:1px solid #fff;
                color:#fff;
            }
            a .multiicon_c:hover i{
                color:$r_base-clr;
            }
            a .multiicon_c .inner_icon-round:before,
            a .multiicon_c:hover .inner_icon-round:before{
                background:$r_base-clr;
                color:#fff;
            }  
        } 
}


.blue_theme {
        .second_icon:before {
            background: $b_base-clr;
            color: $white;
        }
        
        a.boxlink:hover .second_icon:before {
            background: $white;
            color: $b_base-clr;
            border:4px solid $b_base-clr;
        }

        .top_btn {
            border:1px solid $b_base-clr;
            color:$b_base-clr;
            &:focus {
                box-shadow: 0px 0px 0px 2px $b_base-clr inset !important;
            }
            &:hover .top_btn_icon{
                background:$b_btn_clr;
            }
            .top_btn_icon{
                background:$b_btn_clr;
            }
            .top_second_icon{
                background: $white;
                color: $b_base-clr;
                border:1px solid $b_base-clr;
            }
        }
        
        .multiicon_c:hover i{color: $b_hover-clr}
        .multiicon_c:hover .inner_icon-round:before{background: $b_hover-clr}

        .breadcrumb {
            .multiicon_c:hover i{
                color:#777;
            }
            .multiicon_c:hover .inner_icon-round:before,
            .multiicon_c .inner_icon-round:before{
                background:#777;
                border:1px solid #fff;
                color:#fff;
            }
            a .multiicon_c:hover i{
                color:$b_base-clr;
            }
            a .multiicon_c .inner_icon-round:before,
            a .multiicon_c:hover .inner_icon-round:before{
                background:$b_base-clr;
                color:#fff;
            }  
        } 

}
