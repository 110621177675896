html {
  font-size: 100%;
}

* {
  box-sizing: border-box;
  outline: none;
}

a:active,
a:visited,
input:active,
a:focus {
  outline: none !important;
}

body {
  font-family: "OpenSans-Regular";
  font-size: 13px;
  width: 98%;
  margin: 0 auto !important;
  overflow-x: hidden;
}

html {
  font-family: sans-serif;
  /* -webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%; */
}

.pagecadj {
  margin: 100px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

figure {
  margin: 0;
}

textarea {
  resize: none;
}

p {
  margin-bottom: 10px;
  line-height: 1.3;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.clearafter:after,
.inputc:after,
.ngb-dp-week:after,
.ngx-pagination:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.break-word,
.breakword {
  word-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.ow {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.copyright {
  padding: 5px 10px;
  font-size: 12px;
}

.flexc {
  display: flex;
}

.flexgrow_one {
  flex-grow: 1
}

/* overwrite Font Awesome 5 Free because it is not visible. */
.fa-expand:before,
.fa-pagelines:before,
.fa-building-o:before,
.fa-trash-o:before,
.fa-arrows:before,
.fa-sign-out:before,
.fa-compress:before,
.fa-check-circle-o:before,
.fa-exclamation-circle:before,
.fa-clock-o:before,
.fa-refresh:before,
.fa-check-circle:before,
.fa-file-text-o:before,
.fa-share-square-o:before,
.fa-bell:before,
.fa-pencil:before,
.dataparent:before,
.selectli:before,
.selectTableli:before,
.selectli.active:before,
.selectTableli.active:before,
.items:before,
.columnliclick:before,
.fa:before {
  font-family: FontAwesome;
}

.fa-share-square-o:before {
  font-weight: 400;
}

.versionc {
  text-align: right;
}

.ellipsistxt {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsistxt-td {
  max-width: 400px
}

.subtitle {
  font-family: OpenSans-SemiBold;
  font-size: 15px;
  color: #000000;
  margin: 8px 0 5px;
}

li {
  list-style: none;
}

/* */

.one_lh {
  line-height: 1
}

.onetwo_lh {
  line-height: 1.2
}

.content-eo:nth-child(even),
.content-eo-np:nth-child(even) {
  background-color: #fff;
}

.content-eo:nth-child(odd),
.content-eo-np:nth-child(even) {
  background-color: #f6f6ff;
}

.content-eo {
  padding: 10px
}

.parent-eo>.ev-odd {
  padding: 10px
}

.parent-eo>.ev-odd:nth-child(odd) {
  background-color: #fff;
}

.parent-eo>.ev-odd:nth-child(even) {
  background-color: #f6f6ff;
}

.displaynone {
  display: none;
}

.displayblock {
  display: block;
}

.displayblockimp {
  display: block !important;
}

.displayinline {
  display: inline;
}

.displayinlineb {
  display: inline-block;
}

.alignright {
  text-align: right
}

.aligncenter {
  text-align: center
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.lowercase-imp {
  text-transform: lowercase !important;
}

.initialcase {
  text-transform: initial
}

.initialcase-imp {
  text-transform: initial !important
}

.whitebg {
  background: #fff
}

.graylbg {
  background: #f7f7f7
}

.radiuseight {
  border-radius: 8px;
}

.radiusfour {
  border-radius: 4px;
}

.overflowauto {
  overflow: auto
}

.uppercase-tt {
  text-transform: uppercase;
}

.lowercase-tt {
  text-transform: lowercase
}

.capitalize-tt {
  text-transform: capitalize;
}

.graybdr {
  border: 1px solid #ccc
}

.graybdrt {
  border-top: 1px solid #ccc
}

.graybdrb {
  border-bottom: 1px solid #ccc
}

.graybdrl {
  border-left: 1px solid #ccc
}

.graybdrtb {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.lightgraybdrt {
  border-top: 1px solid #eee
}

.lightgraybdrb {
  border-bottom: 1px solid #eee
}

.lightgraybdrtb {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.nobdrimp {
  border: none !important
}

.br-four {
  border-radius: 4px
}


.graydrkbdr {
  border: 1px solid #999
}

.border_imp {
  border: 1px solid #999 !important
}

/* display */
.displayb {
  display: block
}

.dosplayib {
  display: inline-block
}

.dispayi {
  display: inline
}

/* */
.font_normal {
  font-size: 13px;
}

.fontsml {
  font-size: 11px;
  line-height: 1;
}

p.fontsml {
  margin-bottom: 4px;
}

.font-sixteen {
  font-size: 16px
}

.font-eighteen {
  font-size: 18px
}

.font-twone {
  font-size: 21px
}

.font-twfour {
  font-size: 24px
}

.font-thirty {
  font-size: 30px
}

.font-thsix {
  font-size: 36px
}

.bold {
  font-family: "OpenSans-Bold"
}

.semi-bold {
  font-family: "OpenSans-SemiBold";
}

.notification {
  font-size: 14px
}

.notification-sml {
  font-size: 11px;
  color: #fff;
  border-radius: 6px;
  padding-left: 4px;
  padding-right: 4px;
}

.box-icon {
  font-size: 21px
}

/* position */
.zeroleftimp {
  left: 0 !important;
}

/* color */
.red-text {
  color: #f00
}

.white-text {
  color: #fff;
}

.gray-text {
  color: #999;
}

.mute-text {
  color: #d2d6de
}

.navy-text {
  color: #001F3F
}

.teal-text {
  color: #39CCCC
}

.purple-text {
  color: #39CCCC
}

.̀orange-text {
  color: #ff851b
}

.maroon-text {
  color: #D81B60
}

.black-text {
  color: #111111
}

.qubes-text {
  color: #696cf1
}

.desc-text {
  font-size: 11px;
  color: #949494
}

/* background color */
.red-bg {
  background: #f00
}

.white-bg {
  background: #fff;
}

.gray-bg {
  background: #999
}

.navy-bg {
  background: #001F3F
}

.teal-bg {
  background: #39CCCC
}

.purple-bg {
  background: #39CCCC
}

.̀orange-bg {
  background: #ff851b
}

.maroon-bg {
  background: #D81B60
}

.black-bg {
  background: #111111
}

.qubes-bg {
  background: #696cf1
}

.bluelight-bg {
  background: #f3f8fd
}

.lightmo:hover {
  background: #ececff;
  color: #696cf1
}

.status-bg {
  /* background: #686df1; */
  padding: 2px 7px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
}

/* anchor */

a.inline,
a.inline:not([href]):not([tabindex]) {
  display: inline-block;
  padding-right: 10px;
}

a.inline:hover,
a.inline:not([href]):not([tabindex]):hover {
  cursor: pointer;
}

a.inline:last-child {
  padding-left: 0;
}

a.inline i {
  display: inline-block;
  margin-right: 3px;
}

a.nodecoration:hover {
  text-decoration: none;
}

.sublink a {
  display: inline-block;
  color: #fff !important;
  padding: 4px 7px;
  border-right: 1px solid #fff;
  font-size: 11px;
  text-decoration: none;
  cursor: pointer;
}

.sublink a:hover {
  color: #fff;
  text-decoration: none;
}

.sublink a i {
  color: #fff;
  padding-right: 3px;
  padding-left: 3px;
}

.sublink a .notification-sml {
  background: #fff;
}

.primary-sd,
.info-sd,
.success-sd,
.warning-sd,
.danger-sd,
.mute-sd {
  opacity: .4
}

.primary-s-bg,
.info-s-bg,
.success-s-bg,
.waring-s-bg,
.danger-s-bg,
.mute-s-bg,
.warning-s-bg {
  color: #fff;
}

.primary-sd-bg,
.info-sd-bg,
.success-sd-bg,
.warning-sd-bg,
.danger-sd-bg,
.mute-sd-bg,
.warning-sd-bg {
  color: #fff;
}

.bg-btn {
  border-radius: 4px;
  padding: 1px 6px 3px 6px;
  font-size: 11px;
}

.boldtxt {
  font-weight: bold
}

.relativepos {
  position: relative
}

.absoultepos {
  position: absolute;
}

.abs-tr {
  top: 0;
  right: 0
}

.abs-tl {
  top: 0;
  left: 0
}

.abs-br {
  bottom: 0;
  right: 0
}

.abs-bl {
  bottom: 0;
  left: 0
}

.z-one {
  z-index: 1
}

.textc {
  text-align: center;
}

.fl {
  float: left
}

.fr {
  float: right
}

.pointerc {
  cursor: pointer
}

.textalignr {
  text-align: right
}

.zeropbimp {
  padding-bottom: 0 !important
}

.zeroptimp {
  padding-top: 0 !important
}

.zeropbimp {
  padding-bottom: 0px !important;
}

.zeroplimp {
  padding-left: 0 !important
}

.zeroprimp {
  padding-right: 0px !important;
}

.onept {
  padding-top: 1px;
}

.twopt {
  padding-top: 2px;
}

.fivep {
  padding: 5px;
}

.fivepr {
  padding-right: 5px;
}

.fivept {
  padding-top: 5px;
}

.fivepb {
  padding-bottom: 5px
}

.fifteenp {
  padding: 15px;
}

.fifteenptb {
  padding-top: 15px;
  padding-bottom: 15px
}

.fifteen_pt,
.fifteenpt {
  padding-top: 15px
}

.fifteen_pb,
.fifteenpb {
  padding-bottom: 15px
}

.fifteenpr {
  padding-right: 15px
}

.fifteenplr {
  padding-left: 15px;
  padding-right: 15px;
}

.eighteenpl {
  padding-left: 18px
}

.tenprimp {
  padding-right: 10px !important
}

.tenplimp {
  padding-left: 10px !important
}

.tenp {
  padding: 10px
}

.tenpt {
  padding-top: 10px
}

.tenpb {
  padding-bottom: 10px
}

.tenpr {
  padding-right: 10px
}

.tenpl {
  padding-left: 10px
}

.tenptb {
  padding-top: 10px;
  padding-bottom: 10px
}

.fivepl {
  padding-left: 5px
}

.twentypr {
  padding-right: 20px
}

.twentypb {
  padding-bottom: 20px;
}

.twentypt {
  padding-top: 20px
}

.thirtypb {
  padding-bottom: 30px
}

.thirtypt {
  padding-top: 30px
}

.zerombimp {
  margin-bottom: 0px !important
}

.zeromlimp {
  margin-left: 0 !important
}

.zeromrimp {
  margin-right: 0 !important
}

.zeromtimp {
  margin-top: 0 !important
}

.zeromimp {
  margin: 0 !important
}

.twomt {
  margin-top: 2px;
}

.eightmt {
  margin-top: 8px
}

.eightmb {
  margin-bottom: 8px
}

.fivemt {
  margin-top: 5px;
}

.fivemr {
  margin-right: 5px;
}

.fiveml {
  margin-left: 5px
}

.fivemb {
  margin-bottom: 5px;
}

.tenmt {
  margin-top: 10px
}

.tenmr {
  margin-right: 10px
}

.tenml {
  margin-left: 10px
}

.tenmb {
  margin-bottom: 8px
}

.fifteenmt {
  margin-top: 15px;
}

.fifteenmb {
  margin-bottom: 15px;
}

.fifteenmr {
  margin-right: 15px;
}

.fifteenml {
  margin-left: 15px;
}

.tewentymb,
.twentymb {
  margin-bottom: 20px
}

.fourmr {
  margin-right: 4px
}

/* position */

.zeroleftimp {
  left: 0 !important
}

/* min height */
.minhight-th {
  min-height: 200px;
}

.minheight-fh {
  min-height: 400px
}

/* show hide */
.show-sh .show-s,
.show-h {
  display: none !important
}

.show-bg {
  display: none
}

.show-sh .show-h {
  display: inline-block !important;
}

.show-sh .show-hb,
.show-sh .show-bg {
  display: block
}

.show-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: .1;
}

.z-bg {
  z-index: 99
}

.z-h {
  z-index: 100
}

/* percentage width */
.fourtynineper {
  width: 49%;
}

.widthhundred {
  width: 100%
}

.widthhundred-imp {
  width: 100% !important
}

.tewntyperc {
  width: 20%
}

.eightyperc {
  width: 80%
}

.seventyperc {
  width: 70%
}

.thirtyperc {
  width: 30%
}

/* fixed width */
.hunderdtwentyw {
  width: 120px
}

.hunderdfiftyw {
  width: 150px
}

/* height */

.hunderd-ph {
  height: 100%
}

.fiftyh {
  height: 50px;
}



/* button */

button:focus {
  outline: 0;
}

.disabledbtn {
  opacity: .3
}

.qubesbtn {
  color: #fff;
}

/* sweet alert save button */
.swal2-popup .swal2-styled.swal2-confirm,
.swal2-popup .swal2-styled.swal2-confirm:focus {
  border: none;
  outline: none !important;
}

.swal2-popup .swal2-title {
  display: block !important
}

.qubesbtn.small {
  font-size: 10px;
  padding: 3px 5px;
}

.ico-btn {
  background: none;
  padding: 0;
  border: none;
  margin: 0;
}

.iconbtn,
a.iconbtn,
.iconbtn,
a.iconbtn:not([href]):not([tabindex]) {
  border-radius: 5px;
  border: 1px solid rgba(60, 69, 88, 0.1);
  font-family: OpenSans-Semibold;
  font-size: 13px;
  color: #ffffff;
  padding: 5px 5px 5px 7px;
  text-align: center;
}

.iconbtn,
a.iconbtn:not([href]):not([tabindex]) {
  cursor: pointer;
}

.iconbtn-round {
  height: 18px;
  width: 18px;
  font-size: 11px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-inline-ico i {
  padding-left: 7px
}

.closeabs {
  top: 10px;
  right: 15px;
  position: absolute;
  z-index: 1;
}

.closelg {
  font-size: 30px;
  cursor: pointer;
}

.uploadfile input {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer
}

.uploadedfile-txtR {
  position: absolute;
  left: 110px;
  top: 5px;
  z-index: 1;
}

.iconbtn i {
  margin-right: 3px;
}

.tabrightbtnc {
  width: 90px;
  position: absolute;
  top: -35px;
  right: 0px
}

.transferbtnc {
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 60px;
}

.transferbtn {
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  font-size: 24px;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.transferbtn:first-child {
  border-radius: 4px 4px 0 0;
}

.transferbtn:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: none;
}

/* title*/
.pagecaption {
  font-family: OpenSans-SemiBold;
  font-size: 15px;
  color: #000;
  margin: 8px 0 5px;
}

.innrcaption {
  font-family: OpenSans-SemiBold;
  font-size: 15px;
  color: #000;
  margin: 8px 0 5px;
}

/* */

.header {
  z-index: 9;
  position: relative;
}

.header-component {
  z-index: 999;
  border-radius: 8px 8px 0 0;
}

.headnav {
  margin-left: 40px
}

.subhead {
  font-family: OpenSans-Regular;
  margin-top: 5px;
  font-size: 14px;
}

.pageec {
  border-radius: 0 0 8px 8px;
}

.pagec {
  margin-left: 49px;
  padding: 15px;
  transition: margin 1s;
  overflow: auto;
  overflow-x: hidden;
  margin-right: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
}


/* modal popup */

.modal-header {
  border: none;
  padding: 15px;
}

/* .modal-header .close{ 
 padding:0 15px 0 0  
} */

.modal-title {
  margin-top: 0;
}

.modal-content {
  border-radius: 4px;
  
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #999; */
  border: 0px!Important;
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modalfooter {
  display: flex;
}

.done-btn {
  border-radius: none;
  flex-grow: 1;
  border: 0px solid #686df1;
  border-right: 1px solid #a4acff;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  font-family: Raleway-SemiBold;
  font-size: 12px;
  color: #ffffff;
  padding: 7px 12px;
  text-align: center;
}

.done-btn:first-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
}

.done-btn:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border: none;
}

.done-btn:disabled,
.done-btn:disabled:hover,
.btnnext:disabled,
.btnnext:disabled:hover,
.btnpre:disabled,
.btnpre:disabled:hover,
.tertiary-active:disabled,
.tertiary-active:hover:disabled,
.normal-btn:disabled,
.normal-btn:disabled:hover {
  cursor: not-allowed;
}

.grey-btn:disabled {
  background: #f3f3ff;
  cursor: auto;
  color: #c3c3c3;
}

/* input field */

.form-control {
  height: 37px;
}

.fieldc {
  position: relative
}

.inputc-withicon {
  position: relative;
  padding-right: 24px;
}

.withicon {
  position: absolute;
  font-size: 18px;
  top: 5px;
  right: 1px
}

.withicon.topzero {
  top: 0;
}

.nextbtn-input {
  color: #fff;
  border-radius: 0 4px 4px 0;
  height: 38px;
  width: 30px
}

.tab-content .inputlabel {
  background: #f7f7f7
}

.fieldc[class*="col-"]:last-child .inputlabel {
  left: 25px;
}

.fieldc[class*="col-"]:first-child .inputlabel {
  left: 10px;
}

.select-box-dropdown div::after {
  position: absolute;
  right: 10px;
}

.select-box .dropdown-menu {
  right: 0;
}

.inputc {
  margin-bottom: 15px;
  position: relative;
}

.fieldc:last-child {
  padding-left: 15px;
  padding-right: 0;
}

.inputc .fieldc:first-child {
  padding-right: 15px;
  padding-left: 0;
  position: relative;
}

.inputtextb {
  border: 1px solid #e4e4e4;
}

.inputc.fieldtctext {
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 15px 3px 15px;
  margin-bottom: 0;
  position: relative;
  min-height: 36px;
}

.inputtextb .inputc.fieldtctext:last-child {
  border-bottom: none
}

.inputc.fieldtctext:nth-child(even) {
  background: #f5f6fb;
}

.inputc.fieldtctext:nth-child(odd) {
  background: #fff;
}

.labeltext,
.inputtext {
  font-size: 13px;
}

.labeltext {
  width: 180px;
  position: absolute
}

.inputtext {
  margin-left: 184px;
}

.labeltext::after {
  content: ":";
  float: right;
  padding-right: 5px;
}

.inputc-error .selectoption,
.inputc-error .input-field,
.inputc-error .ng-select .ng-select-container {
  position: relative;
}


/* .error-mge{ display:none} */
.inputc-error .error-mge {
  display: block
}

.inputc-error .error-mge,
.error-mge,
/* added the class with-errors and errors to adjust the current error message */
.with-errors,
.errors {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  z-index: 1;
  color: #a94442;
  font-size: 11px;
  margin-bottom: 0;
}

.error-mge-clr {
  color: #a94442;
  font-size: 11px;
}

.fieldc:last-child .error-mge,
.fieldc:last-child .errors,
.fieldc:last-child .with-errors {
  left: 15px;
}

.fieldc:first-child .error-mge,
.fieldc:first-child .errors {
  left: 0px;
}

/* custom check box */

.customcb input[type="checkbox"] {
  position: absolute;
  left: -999px;
}

.customcb input[type="checkbox"]+span {
  font-weight: normal;
  position: relative;
  padding-left: 22px;
}

.customcb input[type="checkbox"]+span:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0c8";
  font-size: 16px;
  text-align: center;
  letter-spacing: 6px;
  color: #ccc;
  position: absolute;
  left: 0
}

.customcb input[type="checkbox"]:checked+span:before {
  content: "\f14a";
  font-family: "Font Awesome 5 Free";
}

/* checked icon */
.customcb input[type="checkbox"]:indeterminate+span:before {
  content: "\f14a";
  font-family: "Font Awesome 5 Free";
  letter-spacing: 6px;
}


/* chosen select option */
.chosen-container-single a.chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
  height: auto;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.chosen-container .chosen-drop {
  left: 0;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
}

.chosen-container-single .chosen-single div b {
  right: -10px;
  position: absolute;
}

.chosen-container-single .chosen-single div b:after {
  content: "\f107";
  display: block;
  font-family: 'Font Awesome 5 Free';
}

.fieldc {
  position: relative
}

.inputlabel {
  width: 100%;
  min-height: 24px;
  margin-bottom: 2px;
  display: block;
  font-weight: normal;
}

.label-asterisk:after {
  content: "*";
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  color: #f00;
  padding-left: 2px;
}

th.label-asterisk:after {
  color: #ffabab
}

.clearafter.label-asterisk:after {
  visibility: inherit;
  height: auto;
  font-size: inherit;
}

.input-field,
.selectoption,
.dropdown-toggle,
.search-bar,
.chosen-container,
.date-pick input,
.multiselect-dropdown .dropdown-btn,
.select-custom {
  width: 100%;
  font-family: OpenSans-Regular;
  font-size: 13px;
  border-radius: 4px;
  padding: 7px 15px 8px 15px;
  box-shadow: none;
  transition: none;
}

.input-field.small,
.selectoption.small {
  font-size: 12px;
  padding: 5px 7px;
}

.input-field.vsmall,
.selectoption.vsmall {
  font-size: 12px;
  padding: 2px 4px;
}

.input-group-append .btn,
.input-group-prepend .btn {
  height: 37px;
}

/* Change Autocomplete styles in Chrome ** blue background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: 333 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}


.ng-select .ng-select-container {
  font-family: "OpenSans-Regular";
  font-size: 12px;
  padding: 9px 10px 9px 15px;
  box-shadow: none !important;
}

.ng-sel-sml .ng-select .ng-select-container {
  padding: 4px 10px;
  min-height: 26px;
  min-height: 26px;
}

.ng-sel-sml .ng-select.ng-select-single .ng-select-container {
  height: 26px;
}

.ng-sel-sml .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 3px 10px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: inherit;
  left: inherit;
  padding-left: 0;
  padding-right: 0;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 0;
}

.ng-select .ng-arrow-wrapper {
  padding-right: 0 !important;
}

.dis-active .ng-select-container {
  pointer-events: none;
  background: #e4e4e4;
  border: 1px solid #d4d4d4;
  cursor: no-drop;
  color: #929292;
}

.category-dd .ng-select .ng-select-container .ng-value-container .ng-input>input {
  padding: 0 10px;
  max-width: 100% !important;
}

.category-dd .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  right: 0;
  max-width: 80% !important
}

.flat-menu-e .dis-active .ng-select-container {
  border: 1px solid #ccc;
  width: 100%;
  color: #333;
  background-color: #fbfbfb;
  pointer-events: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: none !important;
  color: #777 !important;
  font-family: OpenSans-Regular !important;
  font-size: 12px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 4px 10px;
  overflow: auto;
  white-space: inherit !important;
  word-break: break-all;
  text-overflow: inherit;
}

.ng-option-label {
  font-weight: normal !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 180px !important;
}

.modal-body .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 110px !important;
}

.modal-body .multiselect-dropdown .item2 {
  max-height: 90px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected:hover {
  color: #fff !Important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #777;
  border-bottom: 1px solid #eee;
}

.ng-dd-btm .ng-dropdown-panel.ng-select-bottom {
  top: inherit;
  bottom: 100%
}


.input-field.noradious {
  border-radius: 0
}

.input-field.sml {
  padding: 3px 7px
}

.form-control {
  font-family: OpenSans-Regular;
  font-size: 12px;
  color: #333;
}

.input-field:disabled,
.selectoption:disabled,
.dropdown-toggle:disabled,
.search-bar:disabled,
.chosen-container:disabled,
.date-pick:disabled input,
.multiselect-dropdown:disabled .dropdown-btn,
.input-field:read-only,
.ng-select.ng-select-disabled>.ng-select-container {
  cursor: no-drop;
}

.chosen-container {
  width: 100% !important
}

.input-field.medium {
  width: 50%;
}

.searchc {
  position: relative;
  width: 100%
}

.searchc .search-bar {
  padding-right: 25px
}

.searchc .search-bar-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.dropdown-toggle::after {
  content: '';
  display: block;
  /* background-image: url('../../../assets/images/down-arrow.svg'); */
  background-repeat: no-repeat;
  border: 0;
  float: right;
  margin: 6px 0 0 10px;
  height: 7px;
  width: 10px;
}

.dropdown-toggle.nopdbdr {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  background: none;
}

.dropdown-toggle.icondd::after {
  content: hidden;
  display: none;
}

.dropdown-toggle.icondd,
.dropleft .dropdown-toggle.icondd::before {
  border: none;
  background: none;
  padding: 0;

}

.icondd+.dropdown-menu {
  min-width: 100px;
}

.icondd+.dropdown-menu li {
  padding: 3px 6px;
  font-family: OpenSans-Regular;
  font-size: 12px;
}


.dropdown-toggle {
  padding-right: 26px;
}

/* choose button */
.fullwidth-choosen .chosen-container {
  width: 100% !important;
}

.top-msdd .dropdown-list {
  bottom: 100%
}

.top-msdd .dropdown-list ul {
  max-height: 120px !important;
}

.multiselect-dropdown .dropdown-btn {
  padding: 6px 12px 2px 12px;
  min-height: 36px;
}

.searchbarc,
.passwordc {
  position: relative;
}

.show .dropdown-toggle {
  background: #fff
}

.search-bar,
.password-input {
  padding-right: 25px;
}

.searchbarc .search-bar-icon,
.passwordc .password-icon,
.dropdown .ddicon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.searchbarc .search-bar-icon {
  font-size: 18px;
  color: #929292
}

.searchbarc .input-field {
  padding-right: 30px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.searchbarc.sml input {
  height: 32px;
  padding: 3px 30px 3px 8px;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1069 !important;
}

ul.dropdown-menu {
  padding: 0;
}

.dropdown-menu li {
  padding: 7px 10px;
}

.dropdown-menu li:hover {
  color: #ffffff;
}


.inputc label {
  font-weight: normal
}

input:focus,
textarea:focus,
.multiselect-dropdown .dropdown-btn:focus {
  background-color: #fff;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input.middle:focus {
  outline-width: 0;
}

.labeltitle {
  font-family: OpenSans-Regular;
  font-size: 12px;
  color: #3c4558;
}

/* date time picker */
.date-pick {
  position: relative;
  display: inline-block
}

.date-pick:input {
  display: inline-block
}

.date-pick::after {
  content: '';
  cursor: pointer;
  display: inline-block;
  /* background-image: url('../../../assets/images/calendar.svg'); */
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 10px;
  top: 9px;
}

.md-drppicker.double {
  min-width: 600px !important;
}

/* transter block */
.boxc {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.boxc-title {
  background: #f7f7f7;
  padding: 7px 15px;
  border-bottom: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
}

.boxc-content {
  padding: 15px
}

/* box */

.menublock {
  border-radius: 8px;
  box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14);
  cursor: context-menu;
  padding: 15px;
}


.menublockc {
  padding-top: 10px
}

.closebtnc {
  height: 30px;
  position: relative;
}

.close-btn,
.closebtnc .close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}


/* progress bar */

.progress {
  margin-bottom: 0
}

.progressbar {
  counter-reset: step;
  padding: 0;
  color: #333;
}


.progressbar li,
.progressbar.nohover li,
.progressbar.nohover li:hover {
  width: 16.5%;
  float: left;
  position: relative;
  padding: 15px 10px 15px 15px;
  height: 70px;
}


.menublock.tabw_seven .progressbar li,
.menublock.tabw_seven .progressbar.nohover li,
.menublock.tabw_seven .progressbar.nohover li:hover {
  width: 14.2%
}

.progressbar li:first-child {
  border-radius: 4px 0 0 4px;
}

.progressbar li:last-child {
  border-radius: 0px 4px 4px 0;
}

.progressmodel li {
  width: 20%;
}

.progressbar li:after,
.progressbar.nohover li:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: -11px;
  margin-top: -11px;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  z-index: 1;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -ms-transform-origin: 20% 40%;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari 3-8 */
  -webkit-transform-origin: 20% 40%;
  /* Safari 3-8 */
  transform: rotate(45deg);
  transform-origin: 20% 40%;
  border: 1px solid #dedede;
}

.progressbar li:before,
.progressbar.nohover li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 12px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.progressbar.nohover li:hover:before,
.progressbar.nohover li:hover:after {
  cursor: auto;
  ;
}

.progressbar li:last-child:after,
.progressbar li:last-child:before {
  content: hidden;
  display: none;
}

.progressbar li.active:hover {
  cursor: pointer;
}

.progresslic .fade {
  display: none
}

.progresslic .show {
  display: block
}

.progresstab li {
  padding: 0;
}

.progresstab li a {
  padding: 15px 10px 15px 15px;
  background: none;
}

.progresstab.nohover li a,
.progresstab.nohover li a:hover {
  padding-top: 0;
  cursor: default;
}

.progresstab li a:hover,
.progresstab li a:active,
.progresstab li a:focus {
  background: none
}


.stipico {
  width: 24px;
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
}

.steptxtc {
  padding-left: 37px;
}

.steptitle {
  font-size: 14px;
  font-family: "OpenSans-Bold";
  margin-bottom: 0px;
  margin-top: 3px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.2;
}

.stepdes {
  font-size: 12px;
  line-height: 1;
}

.stipico .fa-refresh,
.stipico .fa-balance-scale {
  font-size: 26px;
}

.stipico .fa-arrows {
  font-size: 30px;
}

/* table */
.table-ec {
  margin-bottom: 30px
}

.table-ec h4 {
  font-size: 13px;
}

.selecttd,
.numbertd {
  width: 50px;
}

.idtd {
  width: 32px
}

.mr-table th:first-child,
.mr-table tr td:first-child {
  padding-left: 15px
}

.datetimetd {
  width: 170px
}

.datetimetd-span {
  max-width: 162px
}

.mailtd {
  width: 260px
}

.mailtd-span {
  max-width: 252px
}

.nametd {
  width: 220px
}

.nametd-span {
  max-width: 212px
}

.nametd-sml {
  width: 130px
}

.nametd-sml-span {
  max-width: 114px
}

.descriptiontd {
  width: 260px
}

.descriptiontd-span {
  max-width: 252px
}

.durationtd {
  width: 140px
}

.durationtd-span {
  max-width: 132px
}

.actiontd {
  width: 40px
}

.actiontwotd {
  width: 70px
}

.actionthreetd {
  width: 100px
}

.actionthreetd-span {
  max-width: 92px
}

.actionfourtd {
  width: 140px
}

.actionfourtd-span {
  max-width: 136px
}

.actionsixtd {
  width: 160px;
}

.actionsixtd-span {
  max-width: 144px
}

.actionseventd {
  width: 174px;
}

.actionseventd-span {
  max-width: 132px
}

.actionfivetd {
  width: 190px
}

.actionfivetd-span {
  max-width: 182px
}

.actionsixtd.icons-container img,
.actionsixtd.icons-container i,
.actionseventd.icons-container img,
.actionseventd.icons-container i {
  margin-right: 12px
}

.statustd {
  width: 90px
}

.statustd-span {
  max-width: 74px
}

.recordstd {
  width: 130px
}

.recordstd-span {
  max-width: 122px
}

.recordstd-sml {
  width: 80px
}

.sourcerecordtd {
  width: 140px
}

.sourcerecordtd-span {
  max-width: 132px
}

.counttd {
  width: 100px
}

.counttd-span {
  max-width: 920px
}

.datatypetd {
  width: 130px
}

.datatypetd-span {
  max-width: 122px
}

.datatimetd {
  width: 160px
}

.datatimetd-span {
  max-width: 152px
}

.sizetd {
  width: 140px
}

.sizetd-span {
  max-width: 132px
}

.md-td {
  width: 170px
}

.md-td-span {
  max-width: 162px
}

.nrltd {
  width: 200px
}

.nrltd-span {
  max-width: 192px
}

.cbtd {
  width: 40px
}

.medtd {
  width: 300px
}

.medtd-span {
  max-width: 292px
}

.longtd {
  width: 400px
}

.longtd-span {
  max-width: 392px
}

.longtd-lg {
  width: 500px
}

.longtd-lg-span {
  max-width: 492px
}

.id-withtd {
  width: 900px
}

.smltd {
  width: 100px
}

.build-date {
  width: 120px
}

.build-date-span {
  max-width: 112px
}

.date_txt {
  font-size: 12px;
  color: #6b6b6b;
}

/* table with stripped */

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  border-top: none;
  /* overwrit bootstrap */
}

.table>tbody>tr>td {
  border-bottom: none;
}

.table-wrapper {
  border-radius: 8px;
  /* box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14); */
}

.mr-table {
  font-family: OpenSans-Regular;
  margin-bottom: 0;
  width: 100%;
}

.mr-table .mr-table-pad-left {
  padding-left: 28px;
}


.mr-table-head .mr-table-head-row {
  height: 36px;
  border-radius: 4px 4px 0 0;
  font-weight: normal;
  font-family: "OpenSans-Bold"
}

.header-c {
  font-family: "OpenSans-Bold"
}

.mr-table {
  border-radius: 4px;
  border-collapse: separate;
}

.mr-table tbody {
  border-radius: 0 0 4px 4px
}

.mr-table-head-row th:first-child,
.ag-theme-balham .ag-header-cell:first-child {
  border-radius: 4px 0 0;
}

.mr-table-head-row th:last-child,
.ag-theme-balham .ag-header-cell:last-child {
  border-radius: 0 4px 0 0;
}

.mr-table-body-row:last-child {
  border-radius: 0 0 4px 4px
}

.mr-table tr:last-child td:first-child {
  border-radius: 0 0 0 4px
}

.mr-table tr:last-child td:last-child {
  border-radius: 0 0 4px 0
}


.mr-table-head .mr-table-head-row th,
.ag-theme-balham .ag-header-cell {
  border-bottom: none;
  vertical-align: top;
}

.mr-table-body-row {
  min-height: 46px;
}

/* .mr-table-body-row.active > td,
.mr-table-body-row.active:hover td,
.mr-table-body-row.active:nth-child(odd) td,
.mr-table tbody.active:nth-child(even) td,
.mr-table-body-row.active:nth-child(odd):hover td,
.mr-table tbody:nth-child(even).active:hover td{
	background: #edefff !important;
} */

.mr-table-body-row td {
  vertical-align: middle;
  border: none;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 13px;
}

.table>thead>tr>th.active {
  background-color: inherit;
  color: #fff;
}

.sorticon-c {
  position: relative;
  padding-right: 17px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sorttext {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sorticon-c::before {
  width: 14px;
  height: 13px;
  top: 2px;
  right: 0px;
  position: absolute;
  z-index: 1;
  content: "";
}

.sorticon {
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

/* overwrite ag table */
.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  border: none;
}

.ag-theme-balham .ag-row {
  border-style: none;
}

.ag-theme-balham .ag-row:nth-child(odd) {
  background-color: #ffffff !important;
}

.ag-theme-balham .ag-row:nth-child(even) {
  background-color: #f7f7f7 !important;
}

.ag-theme-balham .ag-cell {
  vertical-align: middle;
  border: none;
  color: #9b9b9b;
}


/* table with drop down content */

.ddtable .mr-table-body {
  background-color: #ffffff;
  padding: 0 17px 17px;
}


.ddtable .status-img-wrp {
  width: 20px;
  height: 20px;
  position: relative;
}

.ddtable .status-img-wrp img {
  height: 100%;
  width: 100%;
}

.ddtable .status-img-wrp::after {
  content: '';
  height: 10px;
  position: absolute;
  bottom: -12px;
  right: 50%;
  width: 1px;
  /* background-image: url(../../../assets/images/list.svg); */
}

.ddtable .action-icon-wrap {
  width: 75%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.ddtable .action-icon-wrap img {
  cursor: pointer;
}

.mr-inner-tbl-wrap {
  border-radius: 8px;
  background-color: #f7f7f7;
}

.mr-inner-tbl-wrap table {
  width: 100%;
}

.mr-inner-tbl-wrap tr {
  height: 40px;
}

.mr-inner-tbl-wrap .mr-inner-tbl-head {
  border-top: 1px solid rgba(60, 69, 88, 0.12);
  border-bottom: 1px solid rgba(60, 69, 88, 0.12);
  color: #3c4558;
}

.mr-inner-tbl-wrap .mr-inner-tbl-head>th:first-child {
  padding-left: 20px;
  width: 8%;
}

.mr-inner-tbl-wrap .start-time-display,
.mr-inner-tbl-wrap .mr-inner-tbl-top-content {
  padding: 16px 20px;
}

.mr-inner-tbl-wrap .start-time-display,
.mr-inner-tbl-wrap .progress-text {
  font-family: OpenSans-SemiBold;
  font-size: 12px;
  color: #3c4558;
}

.mr-inner-tbl-wrap .progress-text {
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.mr-inner-tbl-wrap .job-details-wrp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: OpenSans-Regular;
  font-size: 12px;
  color: rgba(60, 69, 88, 0.7);
  margin-top: 8px;
}

.mr-tbl-first-row {
  background-color: #ffffff;
  height: 56px;
  color: rgba(60, 69, 88, 0.8);
}

.mr-tbl-first-row.adjheight {
  height: 40px
}

.mr-tbl-first-row>td:first-child {
  font-family: OpenSans-SemiBold;
  color: #3c4558;
}

.mr-tbl-first-row .action-icon-wrap {
  width: 60%;
}

.mr-inner-tbl-body td {
  color: rgba(60, 69, 88, 0.7);
  border-bottom: 1px solid rgba(60, 69, 88, 0.12);
  position: relative;
}

.mr-inner-tbl-body td:first-child {
  padding-left: 25px;
  border-bottom: none;
}

.mr-inner-tbl-body td:nth-child(2) {
  color: #3c4558;
}

.mr-inner-tbl-body td:last-child {
  border-bottom: none;
}

.mr-inner-tbl-body td:last-child::after {
  content: '';
  position: absolute;
  bottom: -1px;
  height: 1px;
  width: 80%;
  left: 0;
  border-bottom: 1px solid rgba(60, 69, 88, 0.12) !important;
}

.mr-inner-tbl-body tr:first-child td {
  padding-left: 0;
}

.mr-inner-tbl-body tr:first-child td::after {
  display: none;
}

.mr-inner-tbl-body tr:last-child td {
  border-bottom: none;
}

.mr-inner-tbl-body tr:last-child td:last-child::after,
.mr-inner-tbl-body tr:last-child td .status-img-wrp::after {
  display: none;
}

.mr-rw-toggle {
  display: none;
}

.mr-open .drop-dwn-arw {
  transition: all ease 0.3s;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* pagination */
.pagination {
  float: right;
  margin: 0;
}

.ngx-pagination {
  padding: 0;
  margin-top: 15px;
  float: right;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #eee;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  font-family: FontAwesome !important;
  display: inline-block;
  font-family: OpenSans-SemiBold;
  color: #fff;
  padding: 5px 11px;
  font-size: 20px;
  height: 31px;
  line-height: 1;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\f104" !important;
  border-radius: 4px 0 0 4px;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\f105" !important;
  border-radius: 0 4px 4px 0;
}

.ngx-pagination .pagination-previous span,
.ngx-pagination .pagination-next a span,
.show-for-sr {
  display: none;
}

/* pagination */
.ngx-pagination {
  padding: 0;
  margin-top: 15px;
  float: right;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  font-family: FontAwesome !important;
  display: inline-block;
  font-family: OpenSans-SemiBold;
  color: #fff;
  padding: 5px 11px;
  font-size: 20px;
  height: 31px;
  line-height: 1;
}

.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\f104" !important;
  border-radius: 4px 0 0 4px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\f105" !important;
  border-radius: 0 4px 4px 0;
  margin-left: 0 !important;
}

.ngx-pagination .pagination-previous span,
.ngx-pagination .pagination-next a span,
.show-for-sr {
  display: none;
}

.ngx-pagination .pagination-next {
  padding-right: 0;
}

.ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next.disabled::after {
  opacity: .3;
}

.ngx-pagination .current {
  height: 31px;
  line-height: 2;
}

.pagination-previous>a,
.pagination-next>a,
.ngx-pagination .disabled {
  padding: 0 !important
}

.ngx-pagination a:hover {
  text-decoration: none;
}

.ngx-pagination li {
  margin-left: 0;
  margin-top: 0;
  margin-right: 0 !important;
  float: left;
}

.ngx-pagination a {
  height: 31px;
  line-height: 2;
}

.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  margin-left: 0;
}

/* breadcrumb */
.breadcrumb {
  margin-bottom: 25px;
  background: #fff;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
}

.breadcrumb>li {
  padding: 4px 30px 4px 16px;
  ;
  position: relative;
}

.breadcrumb>li+li:before {
  border: solid black;
  padding: 12px;
  border-width: 0 1px 1px 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -27px;
  bottom: 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: "";
}

.breadcrumb li a {
  font-size: 13px;
}

.breadcrumb>li a:hover:not([href]):not([tabindex]),
.breadcrumb>li a:hover {
  cursor: pointer;
  text-decoration: none;
}

.breadcrumb a i,
.breadcrumb .active i {
  margin-right: 4px
}

.breadcrumb .breadcurmb_img,
.breadcrumb img {
  width: 15px;
  height: 15px;
  margin-right: 4px;
  background-size: 15px auto;
  display: block;
  float: left;
  margin-top: 3px;
  background-repeat: no-repeat;
}

/* icon container */

.table-wrapper {
  border-radius: 8px;
  /* box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14);
	overflow: hidden; */
}

.icons-container {
  display: -ms-flexbox;
  display: flex;
}

.icons-container:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.icons-container img,
.icons-container i {
  height: 14px;
  width: 14px;
  margin-right: 20px;
  float: left;
  font-size: 13px
}

.icons-container img {
  height: 15px;
  width: 15px;
}

.icons-container i {
  margin-top: 0px;
}

.icons-container i:hover {
  cursor: pointer;
}

.icons-container i.font-sixteen {
  font-size: 16px
}

.icons-container a,
.icons-container a:hover {
  color: #9b9b9b;
  text-decoration: none;
}

.select-icon,
.unselect-icon {
  color: #fff;
  top: 0;
  left: 0;
  font-size: 18px;
}

.tab-content {
  padding: 10px;
  border-radius: 0 4px 4px 4px;
  overflow: auto;
}

.tab-maxh,
.tab-maxch .tab-content {
  max-height: 400px
}

.tab-content>.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.tabbar,
.nav-tabs {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
}

.tabitem,
.nav-tabs .nav-link,
.tabitem:not([href]):not([tabindex]) {
  padding: 7px 12px;
  border-bottom: none;
  border-right: none;
  margin-bottom: -1px;
  border-radius: 0;
}

.tabitem:first-child,
.nav-item:first-child .nav-link {
  border-radius: 4px 0 0 0;
}

.tabitem:last-child,
.nav-item:last-child .nav-link {
  border-right: 1px solid #eee;
  border-radius: 0 4px 0 0;
}

.headnav .nav-item:last-child .nav-link {
  border-right: none;
  border-radius: 0px;
}

.tabitem:hover,
.nav-tabs .nav-link:hover,
.tabitem:hover:not([href]):not([tabindex]) {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.tabitem.active,
.tabitem.active:not([href]):not([tabindex]),
.nav-tabs .nav-link.active,
.tabitem.active:hover,
.nav-tabs .nav-link.active:hover {
  cursor: auto;
}

.nav-tabs>li>a {
  margin-right: 0;
}

.nav-tabs .nav-link.disabled {
  border-color: 1px solid #eee;
}

.headbtnc {
  position: relative;
  padding-top: 5px;
}

.headbtnc .headc {
  padding-right: 0;
  display: inline-block;
  margin-bottom: 18px;
  width: 100%;
  min-height: 35px;
  font-size: 24px;
}

.headbtnc .headbtnc {
  position: absolute;
  right: 0;
  top: 5px
}

.headc .subhead {
  padding-right: 210px;
  /* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */
}

/* flex box */

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container.panel-body {
  padding: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container .flex_content {
  width: 33.3%;
  display: inline-block;
  box-sizing: border-box;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}


.flexc {
  display: flex;
  /* or inline-flex */
}


.flexc-wrap {
  flex-wrap: wrap;
  /* flex items will wrap if necessary */
}

.flexc-nowrap {
  flex-wrap: nowrap;
  /* never wrap its items */
}

.flexc-row {
  flex-direction: row;
}

.flexc-reverse {
  flex-direction: row-reverse;
}

.flexc-column {
  flex-direction: column;
}

.flexc-reverse {
  flex-direction: column-reverse;
}


.flexc-nowrap {
  flex-wrap: nowrap;
}

.flexw-reverse {
  flex-wrap: wrap-reverse;
}

.flexc-ai-stretch {
  align-items: stretch;
}

.flexc-ai-flexstart {
  align-items: flex-start;
}

.flexc-ai-flexend {
  align-items: flex-end;
}

.flexc-ai-center {
  align-items: center;
}

.flexc-ai-baceline {
  align-items: baseline;
  /* items gets aligned by the text baseline */
}

.flexc-ac-fs {
  align-content: flex-start;
}

.flexc-ac-fe {
  align-content: flex-end;
}

.flexc-ac-c {
  align-content: center;
}

.flexc-ac-sb {
  align-content: space-between;
}

.flexc-ac-sa {
  align-content: space-around;
}

.flexc-ac-s {
  align-content: stretch;
}

.flexc-justifyc {
  justify-content: center;
}

.flexc-justifys {
  justify-content: flex-start;
}

.flexc-justifye {
  justify-content: flex-end;
}

.flexc-justifysa {
  justify-content: space-around;
}

.flexc-justifsb {
  justify-content: space-between;
}

.growone {
  flex-grow: 1
}

.growtwo {
  flex-grow: 2
}

.growthree {
  flex-grow: 3
}

.growfour {
  flex-grow: 4
}

.growfive {
  flex-grow: 5
}

.growsix {
  flex-grow: 6
}

.growseven {
  flex-grow: 7
}

.groweight {
  flex-grow: 8
}

.grownine {
  flex-grow: 9
}

.flexone {
  flex: 1
}

.basis-zero {
  flex-basis: 0
}

/* tree item */

.datatree,
.searchtree {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #eee;
}

.datatree ul {
  padding: 0;
}

.datatree:last-child,
.searchtree:last-child {
  border-bottom: none;
}

.datatree li {
  padding: 2px 2px 2px 7px;
  min-height: 20px;
  text-transform: uppercase;
}

.datatree li>ul li>ul {
  padding-left: 15px;
}

.datatree li {
  cursor: pointer;
  list-style-type: none;
  word-wrap: break-word;
  position: relative;
  padding-left: 18px;
}


li.selectli,
li.selectTableli {
  padding-left: 18px;
  position: relative;
  word-break: break-all;
  margin-left: 18px;
}

.selectli:hover,
.selectTableli:hover {
  background: #f6f7ff;
}

li.selectli.nohover:hover,
.selectTableli.nohover:hover {
  cursor: auto;
}

.dataparent:before,
.searchparent:before {
  content: "\f1c0";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  top: 2px;
  left: 0;
}

.searchparent {
  padding-left: 17px;
  line-height: 1;
}

.items:before,
.columnliclick:before {
  content: "\f0ce";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
}

.selectli:before {
  content: "\f0db";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  margin-left: -19px;
  position: absolute;
}

.selectTableli:before {
  content: "\f0ce";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  margin-left: -19px;
  position: absolute;
}


.selectli.active:before {
  content: "\f0db";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  margin-left: -19px;
  position: absolute;
  padding-left: 4px;
  color: #fff;
}


.selectTableli.active:before {
  content: "\f0ce";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  margin-left: -19px;
  position: absolute;
  padding-left: 4px;
  color: #fff;
}

li.selectli.active,
li.selectTableli.active,
.columnliclick.active,
.daptpclick.active {
  color: #fff;
}

.nested {
  display: none;
}



.datatree .active {
  display: block;
}

/* pagination */
.rm-default-ul {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.cm-pagination {
  border: 0px solid #f7f7f7;
  border-radius: 4px;
  display: inline-block;
}

.cm-pagination .mr-pagination-ul li {
  font-family: OpenSans-SemiBold;
  font-size: 11px;
  color: #3c4558;
  padding: 7px 10px;
}


.cm-pagination .mr-pagination-ul li:first-child,
.cm-pagination .mr-pagination-ul li:last-child {
  cursor: pointer;
}

.cm-pagination .mr-pagination-ul li:first-child {
  border-radius: 4px 0 0 4px;
}

.cm-pagination .mr-pagination-ul li:last-child {
  border-radius: 0 4px 4px 0;
}

.cm-pagination .mr-pagination-ul li a {
  background-color: inherit;
  border: none;
  padding: 0;
}

.custom-arrw {
  border: solid #3c4558;
  border-width: 0 1.3px 1.3px 0;
  border-color: #ffffff;
  display: inline-block;
  padding: 3px;
}

.rotate-right {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.rotate-left {
  transform: rotate(130deg);
  -ms-transform: rotate(130eg);
  -webkit-transform: rotate(130deg);
}

/* side menu pop up */
.sh-right {
  width: 0;
  display: none
}

.showrb .sh-right {
  display: block;
  width: 44%;
  float: right;
  -webkit-transition: width 0.1s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.1s;
}

.showrb .sh-left {
  width: 55%;
  float: left;
  -webkit-transition: width 0.2s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.2s;
}

.showrb.only-sr .sh-right {
  width: 100%;
  float: none;
  -webkit-transition: width 0.1s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.1s;
}


.btn-ml,
.showrb.only-sr .btn-mr {
  display: none
}

.showrb.only-sr .sh-left {
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.showrb.only-sr .btn-ml {
  display: block;
}

.btn-ml,
.btn-mr {
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 1;
  font-size: 16px;
  cursor: pointer;
  font-size: 21px;
}

.hidepu-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 0;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  z-index: 2;
  background-color: #fff;
  border-radius: 8px;
}

.loader.hidebg {
  display: none;
}

.loader.hidebg.showbg {
  display: block;
}

.loaderico {
  font-size: 30px;
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
}

/* drag and drop box */

.drag-title {
  padding: 8px 10px;
  color: #fff;
  border-right: 1px solid #fff
}

.drag-title:first-child {
  border-radius: 4px 0 0 0;
}

.drag-title:last-child {
  border-right: none;
  border-radius: 0 4px 0 0;
}

.drag-drop {
  min-height: 60px;
}

.drag-content {
  border: 1px solid #ccc;
}

.drag-td {
  border-right: 1px solid #ccc;
  padding: 5px 10px;
}

.drag-td:last-child {
  border-right: none;
}

/* profile */

.profiledd {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 99;
  background: #fff;
  right: 0 !important;
  left: auto !important;
  width: 190px !important;
  padding-top: 0;
  padding-bottom: 0;
}

.profileimg {
  width: 140px;
  height: 140px;
  border-radius: 70%;
  border: 4px solid #bfbfc1;
  overflow: hidden;
  margin: 25px;
}

.profileimg img {
  width: 100%;
  min-height: 100%;
}

.profilebtnc {
  padding: 10px 5px;
  background: #f7f7f7;
  border-top: 1px solid #828dff;
}

/* append */
.appendfile {
  display: none
}

.appendhere .appendc {
  margin-bottom: 7px;
}


/* profile page */

.profileblock {
  overflow: hidden;
  position: relative;
}

.profile-edit {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.profile-bg {
  width: 60%;
  margin: 0px auto 20px;
  border-radius: 15px; 
  border-width: 3px!important;
  box-shadow: -2px 2px 10px 0 rgb(34 75 157 / 14%);
 
}

.profile-img img {
  width: 100%;
  min-height: 100%;
}

.modal-body .profileimgc {
  width: 200px;
  min-height: 140px;
  height: auto;
}

.profilename {
  background: #686df1;
  padding: 10px 20px;
  font-size: 21px;
  color: #fff;
  display: inline-block;
  font-weight: normal;
}

.profile-img-edit {
  width: 230px;
}

.profilec,
.notificationec {
  display: none
}

.profilec.profileshow,
.notificationec.notificationshow {
  display: block
}

.profilefix,
.notificationfix {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.profilefix a {
  margin-top: 0 !important;
}

.tt_static {
  position: absolute;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  padding: 3px 7px;
  z-index: 2;
  left: 56px;
  top: 0;
  font-size: 11px;
  display: none;
}

.profilefix .tt_static {
  position: fixed;
  top: 226px;
  left: 73px;
}

.tt_arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 1px;
  left: 51px;
  z-index: 1;
  display: none;
}

.profilefix .tt_arrow {
  position: fixed;
  top: 226px;
  left: 68px;
}

.showtt .tt_arrow,
.showtt .tt_static {
  display: block;
}

.expandmode .showtt .tt_arrow,
.expandmode .showtt .tt_static {
  display: none;
}


/* overwrite ng bootstrap css */
.popover {
  display: block !important
}

.popover-header {
  font-size: 14px;
  margin: 0;
  padding: 7px 10px;
  font-family: "OpenSans-Regular";
  color: #333;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.popover-body {
  font-size: 12px;
  color: #333;
  font-family: "OpenSans-Regular";
  line-height: 1.1
}

.popover-body br {
  display: block;
  padding-bottom: 7px;
}

.bs-popover-auto[x-placement^=top] .arrow,
.bs-popover-top .arrow {
  bottom: calc((16px + 1px) * -1);
}

.bs-popover-auto[x-placement^=right] .arrow,
.bs-popover-right .arrow {
  left: calc((19px + 1px) * -1);
}

.bs-popover-auto[x-placement^=bottom] .arrow,
.bs-popover-bottom .arrow {
  top: calc((16px + 1px) * -1);
}

.bs-popover-auto[x-placement^=left] .arrow,
.bs-popover-left .arrow {
  right: calc((19px + 1px) * -1);

}

ngb-popover-window.bs-popover-bottom .arrow,
ngb-popover-window.bs-popover-top .arrow {
  margin-left: -16px !important;
}

ngb-popover-window.bs-popover-left .arrow,
ngb-popover-window.bs-popover-right .arrow {
  margin-top: -16px !important;
}

/* bootstrap tooltip */
.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  opacity: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 220px;
  font-size: 11px;
}

.tp-md .tooltip-inner {
  min-width: 114px;
}

.tp-lg .tooltip-inner {
  min-width: 150px;
}


.custom-ttc .tooltip-inner {
  background-color: #f39c12;
}

.custom-ttc.bs-tooltip-auto[x-placement^=top] .arrow::before,
.custom-ttc.bs-tooltip-top .arrow::before {
  border-top-color: #f39c12;
}

.custom-ttc.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.custom-ttc.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #f39c12;
}

.custom-ttc.bs-tooltip-auto[x-placement^=right] .arrow::before,
.custom-ttc.bs-tooltip-right .arrow::before {
  border-right-color: #f39c12;
}

.custom-ttc.bs-tooltip-auto[x-placement^=left] .arrow::before,
.custom-ttc.bs-tooltip-left .arrow::before {
  border-left-color: #f39c12;
}


.tooltip-hide .tooltip.show,
.disabledStack .tooltip.show {
  opacity: 0
}

.disabledStack .tooltip.show {
  opacity: 0;
  display: none;
}

.showrb .tooltip-hide .tooltip.show,
.tooltip-hide .icons-container .tooltip.show {
  opacity: 1;
}

.showrb .tooltip-hide .tooltip-inner {
  max-width: 180px;
  word-wrap: break-word;
}

/* multi select drop down */
.multiselect-dropdown .dropdown-btn .selected-item {
  margin-bottom: 2px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  top: 6px !important;
  border-top: 6px solid #adadad !important;
  border-right: 6px solid transparent !important;
  border-left: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 6px solid #adadad;
  border-right: 6px solid transparent !important;
  border-left: 6px solid transparent !important;
}

/*
.multiselect-dropdown .dropdown-btn .selected-item {
	background:#686df1 !important;
	border:1px solid #686df1 !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before,
.multiselect-item-checkbox input[type=checkbox]:checked + div:before{
	color:#686df1 !important;
	border:2px solid #686df1 !important;
} */

.multiselect-item-checkbox:hover input[type=checkbox]+div:before,
.multiselect-item-checkbox:hover input[type=checkbox]:checked+div:before {
  border: 2px solid #fff !important;
}

/* .multiselect-item-checkbox input[type=checkbox]:checked + div:before,
.ng-option.ng-option-selected.ng-option-marked{
	background:#686df1 !important;
} */

.multiselect-dropdown .multiselect-item-checkbox {
  padding: 3px 10px;
}

.multiselect-dropdown .multiselect-item-checkbox,
.ng-option.ng-option-selected.ng-option-marked {
  border-bottom: 1px solid #e6e6e6;
}

.multiselect-dropdown .multiselect-item-checkbox:last-child {
  border-bottom: none;
}

.multiselect-dropdown .dropdown-list {
  padding: 3px;
  border: 1px solid #e6e6e6 !important;
}

.no-data h5 {
  font-size: 12px;
  margin-bottom: 0;
  color: #333;
}

.multiselect-disable {
  width: 100%;
  font-size: 12px;
  border-radius: 4px;
  padding: 9px 15px;
  box-shadow: none;
  transition: none;
  background: #cecece;
  opacity: 0.6;
  border: 1px solid #f1ebeb;
  cursor: no-drop;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 37px
}

.dropdown-list>ul {
  position: relative;
  /* to solve extra height from table */
}

.adjmultdd .multiselect-dropdown .dropdown-btn .dropdown-down,
.adjmultdd .multiselect-dropdown .dropdown-btn .dropdown-up {
  position: absolute;
  top: 14px !important;
  right: 8px !important;
}

.adjmultdd .multiselect-dropdown .dropdown-btn .dropdown-up {
  top: 11px !important;
}

.adjmultdd .multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 42%;
  padding-right: 12px !important;
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.adjmultdd .multiselect-dropdown .dropdown-btn .selected-item a {
  position: absolute;
  right: 3px;
  top: -3px;
}

/* multiselect dropdown with no checkboxes - simple select option */
.nocb .multiselect-item-checkbox input[type=checkbox]+div:before,
.nocb .multiselect-item-checkbox input[type=checkbox]:checked+div:after {
  display: none !important;
}

.nocb .multiselect-item-checkbox input[type=checkbox]+div {
  padding-left: 0 !important;
}

.nocb .multiselect-dropdown .dropdown-btn .selected-item {
  background: none !important;
  border: none !important;
  color: #333 !important;
}

.nocb .dropdown-list .filter-textbox input {
  padding-left: 0 !important;
}

/* custom multi select */
.customselect .multiselect-item-checkbox input[type=checkbox]+div:before,
.customselect .multiselect-item-checkbox input[type=checkbox]:checked+div:before,
.customselect .multiselect-item-checkbox input[type=checkbox]:checked+div:after,
.customselect .multiselect-dropdown .dropdown-btn .selected-item a {
  display: none !important;
}

.customselect .multiselect-dropdown .dropdown-btn .selected-item,
.customselect .multiselect-dropdown .dropdown-btn .selected-item:hover {
  background: none !important;
  border: none !important;
  color: #333 !important;
  box-shadow: none !important;
}

.customselect .multiselect-item-checkbox input[type=checkbox]+div {
  padding-left: 0 !important;
}

/* .multiselect-dropdown .multiselect-item-checkbox:hover,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover{
	background:#686df1 !important;	
}*/

.multiselect-dropdown .multiselect-item-checkbox:hover input[type=checkbox]+div,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  color: #fff !important;
}

/* switch toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}

/* custom radio button */

.radioc {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
}

.radioc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-r {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
}

.radioc .checkmark-r {
  top: 5px;
}

.radioc:hover input~.checkmark-r {
  background-color: #ccc;
}

.checkmark-r:after {
  content: "";
  position: absolute;
  display: none;
}

.radioc input:checked~.checkmark-r:after {
  display: block;
}

.radioc .checkmark-r:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* custom check box */
.checkboxc {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxc.only-cb {
  padding-left: 18px;
  margin-right: 0;
}

.checkboxc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark-c {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid #e2e2e2;
}


.checkboxc:hover input~.checkmark-c {
  background-color: #ccc;
}

.checkmark-c:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxc input:checked~.checkmark-c:after {
  display: block;
}

.checkboxc .checkmark-c:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* side nav */
.sidebar-wrapper {
  border-radius: 0 0 0 8px;
  width: 49px;
  height: 100%;
  transition: width 1s;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-wrapper ul,
.sidebar-wrapper ul li {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative;
}

.sidebar-wrapper .sidebar-list.active a::before,
.sidebar-wrapper .sidebar-list a:hover::before {
  content: '';
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
  display: inline-block;
  height: 100%;
  width: 2px;
  z-index: 9;
  height: 30px;
}

.sidebar-wrapper .sidebar-list a {
  cursor: pointer;
  padding: 5px 2px 5px 5px;
  display: block;
  border-radius: 7px 0 0 8px;
  margin-left: 7px;
  margin-top: 5px;
  height: 30px;
}

.sidebar-wrapper .sidebar-list a:hover,
.sidebar-list.active a {
  text-decoration: none;
}

.sidebar-list a i {
  font-size: 15px;
  display: inline-block;
  width: 21px;
  text-align: center;
}

.sidebar-list a img {
  width: 20px;
  height: 20px;
}

.sidemenuBtnC {
  margin: 5px 22px 5px 0px;
}

.sidemenuBtn {
  float: right;
  color: #ccc;
  font-size: 14px;
}

.sidemenuBtn:hover {
  cursor: pointer;
}

.sidebar_ico {
  width: 21px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 18px auto;
  margin-right: 3px;
}

.dataexpico {
  margin-left: 2px;
}

.leftnavtxt {
  padding-left: 3px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -200px;
  font-size: 14px;
  /* color:#686df1; */
}

/* expand collapse */
.collapsebtn {
  position: absolute;
  top: 0;
  left: 48px;
  z-index: 90;
  height: 50px;
  width: 40px;
  padding-left: 13px;
  padding-top: 17px;
  cursor: pointer;
}

.collapsebtn.fullwidth {
  left: 190px
}

.collapsebtn:hover {
  color: #fff;
}

.leftanchor i {
  font-size: 16px;
}

.icon-tt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  display: block;
}

.expandmode .icon-tt {
  display: none;
}

.expandmode .collapseBtn,
.expandmode .leftnavtxt {
  display: inline-block;
}

.expandmode .leftnavtxt {
  -webkit-transition: left .4s;
  /* For Safari 3.1 to 6.0 */
  transition: left .4s;
  opacity: 1;
  position: absolute;
  left: 40px;
  top: 3px;
  font-size: 15px;
}

.ico-wt-txt,
.adjusticons .ico-wtno-txt {
  display: none
}

.ico-wt-txt {
  margin-left: -200px;
  transition: margin .2s;
  display: none;
}

.adjusticons .ico-wt-txt {
  display: block;
  width: 190px !important;
  margin-left: 0;
  -webkit-transition: width .1s;
  /* For Safari 3.1 to 6.0 */
  transition: width .5s;
}

.adjusticons .ico-wt-txt.logoc span {
  width: 190px;
  background-repeat: no-repeat;
  height: 35px;
  background-size: 126px auto;
  border: none;
  display: inline-block;
}

.ico-wtno-txt span {
  width: 41px;
  background-repeat: no-repeat;
  height: 35px;
  background-size: 35px auto;
  border: none;
  display: inline-block;
}

.navbar-brand.logoc {
  padding-top: 9px;
  padding-left: 8px;
  padding-bottom: 0;
}

.expandmode .sidebar-wrapper,
.expandmode .logoc {
  width: 191px;
  -webkit-transition: width .5s;
  /* For Safari 3.1 to 6.0 */
  transition: width .5s;
}

.expandmode .pagec {
  margin-left: 191px;
  transition: margin .2s;
}

/* modal pop up */
.modal {
  top: 40px;
}

.modal-header .close {
  padding-top: 0px;
  font-size: 30px;
  padding-top: 5px;
}

.modal-body {
  position: relative;
  padding: 15px;
  min-height: 240px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.modal-body.overflow-v {
  overflow: visible;
}

.progress-bar {
  font-size: 11px;
}

.cart-button {
  width: 81px;
  padding: 5px;
  text-align: center;
  color: #fff;
  box-shadow: 2px 2px 5px #ccc;
  transition: all .2s ease-in-out;
}

.cart-button i {
  width: 100%;
}

.cart-button span.badge {
  position: absolute;
  font-size: 14px;
  top: -6px;
  right: -6px;
}

.cart-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.noGraph {
  text-align: center;
  padding: 8em 0;
}

.widget-user-username {
  overflow: hidden;
  height: 36px;
  margin-bottom: 3px;
}

.widget-user-username::first-letter {
  text-transform: uppercase;
}

/* sweet alert */
.swal2-popup {
  width: auto !important;
  min-width: 350px !important;
  max-width: 480px !important;
}

.swal2-popup .swal2-title {
  font-size: 18px !important;
  font-weight: normal !important;
  margin-bottom: 15px !important;
}

.swal2-icon-text {
  font-size: 36px !important
}

.swal2-icon-text {
  font-size: 36px;
  line-height: 1;
}

.swal2-popup .swal2-content {
  font-size: 14px !important;
  color: #7b7b7b !important;
}

.swal2-popup .swal2-actions {
  justify-content: flex-end !important;
  margin: 0 !important;
  padding-top: 30px;
}

.swal2-modal .swal2-styled:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

/* .swal2-icon{
	width:40px !important;
	height:40px !important;
	margin-top:15px !important;
	margin-bottom:15px !important; 
}

.swal2-icon.swal2-error,
.swal2-icon.swal2-success{
	width:5em !important;
	height:5em !important;
} */

.swal2-icon {
  display: none !important;
}

.swal2-popup .swal2-styled {
  min-width: 74px;
  padding: 8px 10px !important;
  line-height: 1;
  font-size: 13px !important;
  font-weight: normal !important;
  font-family: OpenSans-Semibold !important;
}

.text-info {
  color: #17a2b8 !important;
  font-size: 20px;
  padding: 20px;
}

/* catalog */
.cataloghead-img {
  position: absolute;
  left: 15px;
  top: 10px;
  width: 65px;
}

.cataloghead-img img {
  width: 100%;
}

.catalog-head {
  padding-left: 94px
}

.catlog-head-mh {
  min-height: 86px;
}

.catalogtitle {
  min-height: 32px;
  font-size: 24px;
  color: #fff;
  padding: 0;
  margin: 0;
  padding-bottom: 7px;
  word-wrap: break-word;
}

.catalogtitle::first-letter,
.catalog-sh::first-letter {
  text-transform: uppercase;
}

.catalog-sh {
  font-size: 16px;
  color: #deecff;
  padding-right: 60px
}

.catalog-head-ico {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
}

.catalog-flexc {
  width: 32%;
  margin-bottom: 30px;
  background: #fff;
  margin-right: 2%;
  border-radius: 5px;
  box-shadow: 2px 4px 8px #afa7a7;
}

.catalog-flexc:nth-child(3n) {
  margin-right: 0px;
}


.catalog-cf {
  padding-bottom: 54px;
}

.catalog-content-c {
  min-height: 90px;
  padding: 10px;
}

.catalog-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  /* border-top: 1px solid #dee2e6; */
}

.headtpicker .md-drppicker {
  right: 0 !important;
  left: auto !important;
  top: 52px !important;
}

.calender-ranger {
  width: 200px;
  float: right;
}

.calender-ranger::after {
  content: "\f073";
  display: block;
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  top: 11px;
  right: 6px;
  font-size: 18px;
}

.calender-ranger .input-field:read-only {
  cursor: pointer;
}

.dropdown-menu {
  width: 100%;
}

/* date time ranger */
.md-drppicker td.active.start-date,
.md-drppicker td.active.start-date:hover {
  border-radius: 20px 0 0 20px;
}

.md-drppicker td.active.end-date,
.md-drppicker td.active.end-date:hover {
  border-radius: 0 20px 20px 0;
}

/* custom select option */

.select-custom {
  position: relative;
}

.select-custom select {
  display: none;
  /*hide original SELECT element: */
}

.select-selected:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  top: 11px;
  right: 10px;
  position: absolute;
  color: #ccc;
}

.select-items div,
.select-selected {
  color: #333;
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.select-selected {
  padding: 0;
  border-bottom: none;
}

.select-items {
  position: absolute;
  background: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 170px;
  overflow: auto;
  border: 1px solid #ccc;
}

.top-so .select-items {
  top: auto;
  bottom: 34px;
}

.top-so .ng-dropdown-panel {
  bottom: 100%;
  top: auto;
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  color: #fff !important;
}

.column-in-cuobid {
  color: #000 !important;
  font-weight: bolder;
}

.dbblockc {
  position: relative
}

.graphSubTitle {
  text-align: center;
  color: #000;
  font-size: 15px;
}

.graphTitle {
  font-size: 17px;
  text-align: center;
  color: #000;
  margin-top: 20px;
}

.graphCaption {
  text-align: center;
  font-size: 13px;
  color: #000;
  margin-bottom: 20px;
}

.dropup {
  margin: 20px 5px;
}

.leftblocks {
  width: 68%;
  float: left;
  overflow: visible;
  height: auto;
  position: relative;
  transition: width .5s;
}

.dbblockc.show-o .leftblocks {
  width: 100%;
  float: inherit;
}

.dbblockc.show-o .graphboxc {
  width: 0;
  float: inherit;
  display: none;
}

.graphtogc.show-og .leftblocks {
  width: 0;
  height: 0;
  float: inherit;
  display: none;
}

.graphtogc.show-og .graphboxc {
  width: 100%;
  left: 0;
  transition: left .5s;
  margin-bottom: 15px;
}

.graphboxc {
  width: 31%;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  left: 69%;
  display: block;
  transition: left 0.5s;
  z-index: 0;
  /* animation-name: example;
	animation-duration: .5s;  */
}

.graphc {
  border: 1px solid #eaeaea;
  padding: 5px;
  border-radius: 4px;
  transition: width .1s;
}

.dashBoardGraph .nvd3-svg {
  height: 305px !important;
  padding-top: 40px;
  box-sizing: border-box;
}

.graphtogc.show-og .dashBoardGraph .nvd3-svg {
  height: 380px !important;
  padding-top: 0;
}

.graphboxc .tick.zero,
.graphboxc .tick,
.graphboxc .nv-axisMaxMin {
  display: none;
}

.graphtogc.show-og .graphboxc .tick.zero,
.graphtogc.show-og .graphboxc .tick,
.graphtogc.show-og .graphboxc .nv-axisMaxMin {
  display: block;
}

.flex-container .flex_content {
  width: 33.3%;
}

.show-og .togglebtn-max {
  display: none
}

.show-og .togglebtn-min {
  display: block
}


/* dashboard boxes  */
.db_boxec {
  display: flex;
  flex-wrap: wrap;
  min-height: 0;
  /* animation */
  transition: max-height .4s;
  /* animation */
}

.db_boxc {
  width: 25%;
  display: inline-block;
  box-sizing: border-box;
  min-height: 195px;
  padding: 0px 5px;
margin-bottom: 10px;

}

.db_box {
  padding: 20px 5px 10px;  
  height: 100%;
  border-radius: 5px; border:0px !important;
  box-shadow: -2px 2px 10px 0 rgb(34 75 157 / 14%);

}

.dbblockc.show-o .db_boxc {
  width: 25%
}

.dbblockc.show-o .db_box {
  padding: 20px 7px;
}

.dbblockc.show-o .cardcount {
  font-size: 54px
}

.dbblockc.show-o .cardico {
  margin-bottom: 20px
}

.dbblockc.show-o .cardico i {
  font-size: 42px;
  height: 60px;
}

.dbblockc.show-o .cardcount {
  font-size: 44px;
  margin-bottom: 5px;
}

.dbblockc.show-o .cardtitle {
  font-size: 16px
}

.dbblockc.show-o .cardico .cardimg {
  width: 60px;
  height: 60px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 60px auto;
}

.secondtxt {
  font-size: 19px;
  display: inline-block;
  margin-left: 3px;
}

/* project dashboard */

.graphtwo,
.showgraph .graphone,
.prearrow,
.nextarrow {
  display: none
}

.showgraph .graphtwo,
.shownextpre .nextarrow,
.shownextpre .prearrow

/* .graphtogc.show-og .graphone
 .graphtogc.show-og .graphtwo */
  {
  display: block
}

/* .graphtogc.show-og .prearrow, */
.graphtogc.show-og .nextarrow {
  display: none !important;
}

.prearrow,
.nextarrow {
  position: absolute;
  top: 50%;
  font-size: 30px;
  color: #6f6f6f;
  z-index: 1;
}

.prearrow {
  right: 20px
}

.nextarrow {
  left: 20px;
}

.dashBoardGraph .nvd3-svg text {
  fill: #333 !important;
  stroke-width: 0 !important;
}

.dashBoardGraph .nv-axislabel {
  transform-origin: 3px 0px;
  font-size: 12px;
}

.dashBoardGraph .tick.zero line,
.dashBoardGraph .domain {
  fill: #333 !important
}

/* loader */

.sidebar-wrapper {
  z-index: 9
}

.slim-loading-bar {
  position: static !important;
}


.slim-loading-bar-progress {
  position: fixed !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  transition: inherit !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 8 !important;
  height: auto !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 80px 80px;
}

.search-area input:focus,
.search-area .form-control:focus,
.search-area button:focus,
.search-area .btn:focus {
  box-shadow: none
}

/* loader for table content */

.table-rel {
  position: relative;
  min-height: 160px;
}

.table-loadbg {
  position: absolute;
  top: 37px;
  right: 02px;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: #fff;
}

.table-loader {
  position: absolute;
  top: 50px;
  left: 50%;
  z-index: 3;
  margin: 0 0 0 -25px;
  width: 50px;
}

.spinner-border {
  display: inline-block;
  width: 60px !important;
  height: 60px !important;
  vertical-align: text-bottom;
  border: none !important;
  border-radius: 0 !important;
  -webkit-animation: none !important;
  animation: none !important;
  background-repeat: no-repeat;
  background-size: 60px 60px;
}

/* dashboard accodrion */

.db_boxec .overview-btnc,
.graphboxc .overview-btnc,
.canvasContent .overview-btnc,
.canvasinputec .overview-btnc {
  display: none;
  border-bottom: none;
}

.showbtn_b.overview-btnc,
.showbtn_g.overview-btnc {
  display: block;
}

.db_boxec .iconbtn-round,
.graphboxc .iconbtn-round,
.canvasContent .iconbtn-round,
.canvasinputec .iconbtn-round.topbtn,
.close_fs {
  top: -15px;
  right: -6px;
  border-radius: 0;
  height: 36px;
  width: 36px;
  border: 1px solid #fff;
  font-size: 24px;
  border-radius: 50%;
}

.close_fs {
  position: absolute;
  z-index: 100;
  padding-left: 9px;
  cursor: pointer;
}

.canvasinputec .iconbtn-round.topbtn {
  right: auto;
  left: -6px;
}

.show_vm .canvasinputec .iconbtn-round.topbtn {
  right: -6px;
  left: auto;
}

.graphboxc .iconbtn-round {
  left: -6px;
  right: auto;
}

.overview-blockc {
  position: relative;
}

.overview-block {
  min-height: 0px;
  -webkit-transition: max-height .4s;
  /* For Safari 3.1 to 6.0 */
  transition: max-height .4s;
  margin-bottom: 24px;
}

.togglebtn-ov {
  position: absolute;
  top: -6px;
  right: 1px;
  z-index: 9;
  text-align: center;
}

.graphboxc .togglebtn-ov,
.leftblocks .togglebtn-ov,
.canvasContent .togglebtn-ov {
  z-index: 9999;
}

.dbblockc.show-o .togglebtn-ov {
  right: -8px;
}

.togglebtn-ov.btm {
  bottom: -9px;
  top: auto;
  right: 5px;
}

.overview-btnc {
  border-bottom: 1px solid #ccc;
}

.togglebtn-min {
  display: none;
}

.show-o .togglebtn-max {
  display: none
}

.show-o .togglebtn-min {
  display: block
}

.show-o .overview-btnc {
  bottom: -18px;
}

/* Data Catalog Category */
.flex-container .category-box {
  width: 20%;
  display: inline-block;
  box-sizing: border-box;
}

.gridc-two {
  display: grid;
  grid-template-columns: auto auto;
}

.category-box .image {
  padding: 7px 0;
  text-align: center;
}

.category-box .image i {
  color: #989898;
  font-size: 60px;
  text-align: center;
}

.category-box .overview-card-wrapper {
  padding-bottom: 40px;
}

.cardico,
.cardcount,
.cardtitle {
  text-align: center;
}

.cardico {
  margin-bottom: 10px;
}

.cardico i {
  display: inline-block;
  font-size: 32px;
  height: 44px;
}



.cardico .cardimg {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 40px auto;
}

.cardcount {
  font-size: 44px;
  margin-bottom: 14px;
  line-height: 1;
}

.cardtitle {
  font-size: 14px
}

/* ingestion config */

.flex-container .ing_content {
  width: 33.3%;
  display: inline-block;
  box-sizing: border-box;
}

.ing-boxc {
  padding-bottom: 36px
}

.ing-iconc {
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 1;
  margin-bottom: 0;
}

.ing-box-icon,
a:not([href]):not([tabindex]) .overview-card-wrapper:hover .ing-box-icon:hover {
  float: right;
  width: 30px;
  height: 32px;
  text-align: center;
  display: block;
  font-size: 14px;
  padding-top: 8px;
  cursor: pointer
}

.ing-box-icon:hover,
.overview-card-wrapper:hover .ing-box-icon,
a:not([href]):not([tabindex]) .overview-card-wrapper:hover .ing-box-icon {
  color: #fff;
}

.overview-desc {
  font-size: 12px;
  padding: 10px 0;
  color: #333;
}

.overview-card-wrapper:hover .overview-desc,
.overview-card-wrapper:hover .ing-box-icon,
.category-box .overview-card-wrapper:hover .image i {
  color: #fff;
}

a:not([href]):not([tabindex]) .overview-card-wrapper:hover .overview-desc {
  color: #333;
}


/* visual moduler */
.completeSection {
  position: relative;
  z-index: 1;
}

.canvasinput {
  padding-right: 10px;
}

.canvasinputc,
.sourcebox {
  overflow: auto
}

.cnvasSection {
  min-height: 526px;
  z-index: 8;
  margin-bottom: 2px;
}

.canvasBtnBox {
  width: 18%;
  float: left;
  position: relative;
}

.canvasContent {
  width: 58%;
  float: left;
  border-radius: 8px;
  z-index: 1;
  margin-left: 1%;
  position: relative;
  -webkit-transition: width 0.2s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.2s;
}

/*
.canvasContent_c{
	overflow:hidden;
} */

.canvasinputec {
  width: 22%;
  float: right;
  position: relative;
  -webkit-transition: width 0.2s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.2s;
  padding-right: 5px !important;
}

.vcube .canvasinputec,
.vcube .canvasinput {
  padding: 0 !important
}

.visualcube .threeboxes .hidetree .canvasBtnBox {
  display: none
}

.visualcube .threeboxes .hidetree .canvasContent {
  width: 77%;
  margin-left: 0;
}

.visualcube .canvasContent {
  width: 77%;
  margin-left: 0;
}

.visualcube .threeboxes .canvasContent {
  width: 58%;
  margin-left: 1%;
}

.visualcube .show-canvasinput .canvasinputec,
.visualcube .show-canvasinput.threeboxes .hidetree .canvasinputec,
.visualcube .show-canvasinput .canvasinputec,
.visualcube .show-canvasinput.threeboxes .hidetree .canvasinputec {
  width: 100%;
}

.show-canvas .canvasContent {
  width: 100%
}

.visualcube .show-canvasinput.threeboxes .canvasinputec,
.visualcube .show-canvas.threeboxes .canvasContent {
  width: 81%;
}



.show-canvas .hidetree .canvasContent {
  width: 100%
}

.show-canvas .canvasinputec {
  display: none;
  width: 0px;
}

.show-canvasinput .canvasinputec {
  width: 81%
}

.show-canvasinput .hidetree .canvasinputec {
  width: 100%
}

.hidetd {
  display: none
}

.show-canvasinput .canvasinputec .hidetd,
.show-canvasinput .hidetree .canvasinputec .hidetd {
  display: table-cell
}

.show-canvasinput .canvasContent {
  display: none;
  width: 0px;
}

.show-canvasinput .canvasinputec .togglebtn-max,
.show_vm .canvasinput-fs .togglebtn-fs {
  display: none;
}

.show-canvasinput .canvasinputec .togglebtn-min,
.show_vm .canvasinput-fs .togglebtn-close {
  display: block;
}


.canvasboxes {
  padding: 15px;
  border-radius: 4px;
}

.canvasinput .fieldc.col-md-6 {
  width: 100%;
  max-width: 100%;
}

.show-canvasinput .canvasinputec .canvasinput .fieldc.col-md-6,
.canvasinput .modal-body .inputc .fieldc.col-md-6 {
  width: 50% !important
}

.show-canvasinput .canvasinputec .inputc .fieldc:first-child,
.canvasinput .modal-body .inputc .fieldc.col-md-6 {
  padding-right: 15px;
  padding-bottom: 0;
}

.canvasinput .inputc .fieldc:first-child {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
}

.canvasinput .inputc .fieldc:last-child {
  padding-left: 0px;
}

.canvasinput textarea {
  min-height: 170px;
}


.canvasinput .table-wrapper {
  overflow: hidden;
}

.show-canvasinput .canvasinputec .table-wrapper {
  overflow: visible;
}


.canvasBtns {
  position: absolute;
  left: 15px;
  top: 18px;
  width: auto;
  z-index: 6;
}

.canvasBtns a {
  display: inline-block;
  margin-right: 1px;
  background: #5184a0;
  padding: 2px;
  height: 25px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  color: #fff !important;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  float: left;
}

.contentInner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvasli a {
  position: relative;
}

.canvasli a:before {
  content: "\f0ce";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  left: 5px;
  top: 0px;
}

.canvasBtnBox .btn {
  text-align: left;
}

.canvasData {
  z-index: 5;
  height: 526px;
}

.canvasList {
  position: absolute;
  width: 225px;
  top: 50px;
  list-style: none;
  margin: auto;
  border: 1px solid #c1c1c1;
  padding-left: 0;
  border-radius: 4px;
  overflow: hidden;
  z-index: 4;
  left: 50%;
  transform: translateX(-50%);
}

.scrollSpan {
  max-height: 180px;
  overflow: auto;
  display: block;
}

.canvasList li {
  border-bottom: 1px solid #c1c1c1;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
}

.canvasList li.listTitle {
  background: #3E7E9C;
  padding: 8px 10px;
  color: #fff;
  cursor: move;
}

.canvasList li:last-child {
  border-bottom: none;
}

.canvasList li span {
  display: inline-block;
  font-size: 14px;
  padding: 0px 2px;
}

.canvasList li span i {
  display: inline-block;
}

.canvasList li .edit {
  display: block;
  cursor: pointer;
  float: right;
}

.canvasList li .spanli {
  position: relative;
  display: block;
}

.canvasList li .spanli i {
  position: absolute;
  top: 4px;
  left: 0;
  z-index: 1.2;
}

.canvasList li .spanli .ttxt {
  width: 182px;
  margin-left: 18px;
  line-height: 1;
  min-height: 24px;
  /* required to show bottom li pointer circle */
}

.canvasList li.listTitle {
  padding: 4px 10px;
  font-size: 14px;
  min-height: 30px;
}

.canvasList li {
  padding: 3px 10px;
  font-size: 12px;
}

.canvasList li span {
  font-size: 14px;
}

.canvasBtnBox .btn span {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
  display: block;
  word-break: break-word;
  line-height: 1;
  font-size: 12px;
  font-weight: normal;
}

.canvasPreview {
  position: absolute;
  right: 15px;
  top: 18px;
  border-radius: 4px;
  width: 150px;
  height: 115px;
  z-index: 6;
  overflow: hidden;
}


.previewBox {
  height: 40px;
  z-index: -1;
  cursor: move;
  width: 50%;
  margin: 25%;
}

.list-title {
  position: absolute;
  top: 4px;
  left: 26px;
  right: 34px;
}

.listdelete,
.listedit {
  position: absolute;
  width: 14px;
  top: 4px;
  z-index: 1
}

.listdelete {
  left: 11px;
}

.listedit {
  right: 11px;
}

.list-ttxt {
  margin-right: 20px;
  margin-left: 18px;
  word-wrap: break-word;
  line-height: 1.2;
  width: 168px;
  display: block;
  /* overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; */
  min-height: 24px;
  /* required to show top li pointer circle */
}

.closeList {
  margin-right: 5px;
  cursor: pointer;
}

.vm_body {
  width: 140px;
  max-height: 130px;
  overflow: auto;
  border: 1px solid #c1c1c1;
}

.vm_title {
  width: 140px;
  padding: 5px 7px;
  word-wrap: break-word;
  background: #191919;
  fill: #fdb913;
}

.vm_body table {
  max-width: 100% !important;
  min-width: 100% !important;
}


/*
.svgClass{
	position: absolute;
	top: 12px;
	left: 18px;
	width: 100%;
	height: 100%;
	z-index: -1;
  }

.point{
	width: 10px;
	height: 10px;
	background: red;
	position: absolute;
	right: 0;
	border-radius: 5px;
	z-index: 200;
}
  
.svgPoint{
	position: absolute;
	height: 16px;
	width: 16px;
	z-index: 200;
	border-radius: 16px;
}
  
.svgPointRight{
	width: 16px;
	height: 16px;
	position: absolute;
	right: -5px;
	border-radius: 50%;
	right: 0px;
	top: 10px;
}
.svgPointRightCircle{
	width: 16px;
	height: 16px;
	position: absolute;
	border-radius: 50%;
	right: 0px;
	top: 5px;
}
.svgPointRight{
	width: 1px;
	height: 1px;
	position: absolute;
	right: 7px;
	top: 18px;
}
.svgPointLeftCircle{
	width: 16px;
	height: 16px;
	position: absolute;
	left: -1px;
	top: 5px;
}
.svgPointLeft{
	width: 1px;
	height: 1px;
	position: absolute;
	left: 7px;
	top: 18px;
} */

/* .vm_body td{
	background:#25252a;
	fill:#fff;
	color:#fff;
	max-width:100%;
	word-wrap:break-word;
} */

.show-canvas .togglebtn-min,
.show_vm .togglebtn-fs,
.show_vm .show-fs .togglebtn-close {
  display: block;
}

.show-canvas .togglebtn-max,
.togglebtn-fs,
.show_vm .show-fs .togglebtn-fs,
.togglebtn-close {
  display: none;
}

.show_vm .togglebtn-max,
.show_vm .togglebtn-min {
  display: none !important;
}

.visualm-c {
  display: none
}

.show-o .visualm-c {
  display: block
}

.resizebtn,
.show_vm .fullsbtn,
.minsbtn,
.vmfullec,
.canvasContent_bg,
.fontminus,
.font_lrg .fontplus {
  display: none
}

.show_vm .vmfullec,
.show_vm .show-fs .canvasContent_bg,
.font_lrg .fontminus {
  display: block
}

.show_vm .minsbtn,
.fullsbtn {
  display: block;
  width: 21px;
  height: 25px;
  padding-top: 6px;
}

.show_vm .vmfullc,
.show_vm .show-fs .canvasContent,
.show_vm.visualcube .show-fs .threeboxes .hidetree .canvasContent,
.show_vm .canvasinput-fs .canvasinputec,
.fulls_c {
  position: fixed;
  top: 15px;
  right: 1%;
  bottom: 10px;
  left: 1%;
  z-index: 100;
  width: 98%;
}

.show_vm .vmfullec,
.show_vm .show-fs .canvasContent_bg,
.show_vm .canvasinput-fs .canvasContent_bg,
.full_bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 98;
}

.show_vm .canvasContent {
  z-index: 99;
}

.show_vm .show-fs .canvasContent,
.show_vm.visualcube .show-fs .threeboxes .hidetree .canvasContent {
  z-index: 102;
  right: 0;
  left: 0;
  bottom: 6px;
}

.show_vm .canvasinput-fs .canvasinputec {
  z-index: 102;
  bottom: 2px;
}

.show_vm .show-fs .canvasContent_bg,
.show_vm .canvasinput-fs .canvasContent_bg {
  z-index: 101;
  display: block;
  background: #000;
  opacity: 0.5;
}

.fulls_c {
  z-index: 99;
  border-radius: 6px;
}

.full_bg {
  z-index: 98;
  opacity: 0.5;
}

/* max min button adjustment */
.fullminbtn_inr,
.show_vm .show-fs .fullminbtn_bg {
  display: block;
}

.fullminbtn_bg {
  display: none;
}

.fullminbtn_inr {
  margin-top: -2px;
}

.fullminbtn_bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  opacity: .7;
  border-radius: 2px;
  cursor: no-drop;
}

/* accordion */
.card-header {
  padding: 0
}

.accordion_c .card-header {
  padding: .20rem 1.25rem .50rem
}

.card-header .btn {
  display: block;
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
}

.card-header .btn {
  text-decoration: none;
}

.card-body {
  padding: .75rem;
}

.canvasinput .card-body {
  padding: .50rem;
}

/* visual model list items */

.canvasList.visual_lt {
  width: 176px;
}

.visual_lt.canvasList li,
.visual_lt.canvasList li.listTitle {
  padding: 3px 5px;
  font-size: 12px;
  min-height: 24px;
}

.visual_lt.canvasList li span {
  font-size: 12px;
}

.visual_lt.canvasList .listdelete {
  left: 8px;
}

.visual_lt.canvasList li .checkmark-c {
  height: 14px;
  width: 14px;
}

.visual_lt .checkboxc {
  position: absolute;
  top: 2px;
  left: 0;
}

.visual_lt.canvasList li .slider:before {
  height: 8px;
  width: 8px;
}

.visual_lt.canvasList li .switch {
  width: 24px;
  height: 16px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.visual_lt.canvasList li span.list-ttxt {
  padding-left: 17px;
  padding-right: 34px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.visual_lt.canvasList li .spanli .ttxt {
  /* padding-right:34px; */
  /* enable while adding swith */
  padding-left: 16px;
  margin-left: 0;
  width: 100%;
}

.fontminus {
  padding-top: 5px
}

.fontplus:after,
.fontminus:after {
  font-family: FontAwesome;
  position: absolute;
  top: 6px;
  right: 2px;
  font-size: 8px;
}

.fontplus:after {
  content: "\f067";
  right: 3px;
  font-size: 6px
}

.fontminus:after {
  content: "\f068";
}

.edit_vm:after {
  content: "\f14b";
  left: 2px;
  top: -9px;
  font-size: 14px;
  background: #fff;
  z-index: 1;
  position: absolute;
}

.dataexp:after {
  content: "\f002";
  left: 21px;
  top: 10px;
  font-size: 12px;
  z-index: 1;
  position: absolute;
  color: #000;
}

/* increase the font size */

.font_lrg .canvasList.visual_lt {
  width: 225px;
}

.font_lrg .visual_lt.canvasList li,
.font_lrg .visual_lt.canvasList li.listTitle {
  padding: 4px 10px;
  font-size: 14px;
}

.font_lrg .visual_lt.canvasList .listdelete {
  left: 13px
}

.font_lrg .visual_lt.canvasList li span {
  font-size: 14px;
}

.font_lrg .visual_lt.canvasList li .checkmark-c {
  height: 16px;
  width: 16px;
}

.font_lrg .visual_lt.canvasList li .spanli .ttxt {
  padding-right: 34px;
  padding-left: 20px;
}

.font_lrg .visual_lt.canvasList li .slider:before {
  height: 12px;
  width: 12px;
}

.font_lrg .visual_lt.canvasList li .switch {
  width: 34px;
  height: 20px;
}

.font_lrg .visual_lt.canvasList li span.list-ttxt {
  padding-left: 22px;
  padding-right: 34px;
}

.font_lrg .listedit {
  right: 22px
}

.font_lrg .visual_lt.canvasList li .switch {
  right: 0
}

.cdk-drop-list {
  border-radius: 4px;
}

/* table column flex */

.col-container {
  display: table;
  width: 100%;
}

.col {
  display: table-cell;
}

.beta_vc {
  padding-right: 12px;
}

.beta_v {
  /* position:absolute;
    top:2px;
    right:-13px;*/
  padding: 4px;
  line-height: 1;
  border-radius: 4px;
  font-size: 9px;
  color: #fff;
  background: #1dc143;
  width: 26px;
}

.modal-backdrop {
  z-index: 94;
}

.qubzimg_footer {
  height: 20px;
  padding-right: 3px;
  display: inline-block;
  border: 0;
  width: 72px;
  background-size: 70px 17px;
  background-repeat: no-repeat;
}

.inputfile_w {
  position: relative;
  width: 100%;
  height: 38px;
}

.inputfile_w input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 14px;
  color: #fff
}

.inputfile_w .choose-button {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
}

/* cubes table width adjust */
.expandmode .datetime-cubes {
  width: 124px;
}

.expandmode .datetime-cubes-span {
  max-width: 114px;
}

.datetime-cubes {
  width: 186px;
  font-size: 12px;
}

.datetime-cubes-span {
  max-width: 170px
}

/* project table */
.expandmode .datetime-project {
  width: 190px;
}

.expandmode .datetime-project-span {
  max-width: 174px;
}

.datetime-project {
  width: 190px;
  font-size: 12px;
}

.datetime-project-span {
  max-width: 174px
}

.count_c {
  padding: 2px 4px;
  line-height: 1;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
  margin-left: 4px;
}


@media only screen and (max-width: 600px) {
  .col {
    display: block;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1366px) {
  .flex-container .flex_content {
    width: 25%;
  }
}

@media (min-width: 1599px) {
  .nametd {
    width: 244px;
  }

  .descriptiontd {
    width: 290px
  }

  .flex-container .flex_content {
    width: 20%;
  }

  .overview-card-wrapper {
    padding: 30px 10px 30px 30px;
  }

  .overview-card-wrapper .overview-title {
    font-size: 24px;
  }

  .overview-card-wrapper .overview-count {
    font-size: 72px;
  }

  .overview-card-wrapper .mr-cm-img-wrp {
    top: 6px;
  }
}


@media (min-width: 1710px) {
  .nametd {
    width: 254px;
  }

  .descriptiontd {
    width: 310px
  }
}


@media (min-width: 1899px) {
  .nametd {
    width: 280px;
  }

  .descriptiontd {
    width: 330px
  }
}

@media (min-width: 1800px) {

  /* cubes table width ajust */
  .datetime-cubes {
    width: 110px;
  }

  .datetime-cubes-span {
    max-width: 96px;
  }

  .expandmode .datetime-cubes {
    width: 70;
  }

  .expandmode .datetime-cubes-span {
    max-width: 57px;
  }
}

@media (max-width: 1280px) {

  .icons-container i,
  .icons-container img {
    margin-right: 8px;
    margin-bottom: 5px;
  }

  .nametd {
    width: 180px
  }

  .nametd-span {
    max-width: 172px
  }

  .sizetd {
    width: 100px
  }

  .sizetd-span {
    max-width: 92px
  }

  .descriptiontd {
    width: 180px
  }

  .descriptiontd-span {
    max-width: 172px
  }

  .build-date {
    width: 100px
  }

  .build-date-span {
    max-width: 92px
  }

  .nametd-sml {
    width: 100px
  }

  .nametd-sml-span {
    max-width: 92px
  }

  .longtd-lg {
    width: 350px
  }

  .longtd-lg-span {
    max-width: 342px
  }

  .recordstd {
    width: 110px
  }

  .recordstd-span {
    max-width: 102px
  }

  .id-withtd {
    width: 600px
  }

  .id-withtd-span {
    max-width: 592px
  }

  .actionsixtd {
    width: 170px
  }

  .actionseventd {
    width: 186px
  }

  .actionfivetd {
    width: 140px
  }

  .actionfourtd {
    width: 110px
  }

  .actionthreetd {
    width: 80px
  }

  .actiontwotd {
    width: 60px
  }

  .actiononetd {
    width: 40px
  }

  .actionfourtd-span {
    width: 112px
  }

  .stepdes {
    font-size: 11px;
  }
}



@media (max-width: 1180px) {

  .icons-container i,
  .icons-container img {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .nametd-sml {
    width: 80px
  }

  .nametd-sml-span {
    max-width: 72px
  }

  .sizetd {
    width: 80px
  }

  .sizetd-span {
    max-width: 72px
  }

  .descriptiontd {
    width: 110px
  }

  .descriptiontd-span {
    max-width: 102px
  }

  .longtd-lg {
    width: 300px
  }

  .longtd-lg-span {
    max-width: 292px
  }

  .recordstd {
    width: 100px
  }

  .recordstd-span {
    max-width: 92px
  }

  .id-withtd {
    width: 500px
  }

  .id-withtd-span {
    max-width: 492px
  }

  .actionsixtd {
    width: 160px;
  }

  .actionseventd {
    width: 174px
  }

  .actionfivetd {
    width: 130px
  }

  .actionfourtd {
    width: 100px
  }

  .actionfourtd-span {
    width: 92px
  }

  .stepdes {
    font-size: 10px;
  }
}

@media (max-width: 1080px) {}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding: 10px;
}

.loader {
  opacity: 0.6;
  z-index: 3;
  left: -15px;
  right: -15px;
}

.loaderico {
  z-index: 4;
  top: 260px;
}

.calenderReadonly {
  cursor: pointer !important;
  background: #fff !important;
}

.itemSeperator {
  padding: 2px 3px;
  border-radius: 2px;
  color: #fff;
  margin: 4px 4px 0 0;
  width: auto;
  float: left;
}

.popover-body .btn-outline-secondary {
  margin-top: 2px;
}
