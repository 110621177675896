.overview-card-wrapper {
    padding:20px 7px;
    border-radius: 0px;
    height:100%;
}

.overview-card-wrapper .overview-heading-wrp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position:relative;
}

.overview-card-wrapper .overview-title {
    font-size:15px;
    padding-left:28px;
    width:100%;
}

.overview-card-wrapper .overview-count {
    font-size: 55px;
    font-family:sans-serif;
}

a.boxlink{
    color:inherit;
    text-decoration:none;
    display:block;
    height:100%;
}

a.boxlink[routerlink]:hover{
    cursor:pointer;
    text-decoration:none;
    color: inherit;
    border-radius:4px;
}

/*
a.boxlink[routerlink]:hover .mr-cm-img-wrp,
a.boxlink[routerlink]:hover .overview-title,
a.boxlink[routerlink]:hover .overview-count,
a.boxlink[routerlink]:hover .box-icon,
.box-active .mr-cm-img-wrp,
.box-active .overview-title,
.box-active .overview-count,
.box-active .box-icon,
.box-active:hover .mr-cm-img-wrp,
.box-active:hover .overview-title,
.box-active:hover .overview-count,
.box-active:hover .box-icon,
a.boxbuttons:hover .mr-cm-img-wrp,
a.boxbuttons:hover .overview-title,
a.boxbuttons:hover .overview-count,
a.boxbuttons.box-active:hover .box-icon{
    color:#fff
} */

a.boxlink .imgicon{
    width:24px;
    height:24px;
    display:inline-block;
}

a.boxlink  .imgicon.virtualst{
    background-image: url("../../assets/images/virtualSources-grey.png");
}

a.boxlink[routerlink]:hover .imgicon.virtualst{
    background-image: url("../../assets/images/virtualSources_wh.png");
}
    
a.boxbuttons:hover{
    cursor:pointer;
    text-decoration:none;
    color: inherit;
    border-radius:4px;
}

.mr-cm-img-wrp {
    position:absolute;
    height: 26px;
    margin-right: 4px;
    width: 26px;
    top:0px;
    left:0;
    text-align:center;
}

.box-icon{
    font-size:24px;
}

.grphsub {
    position:absolute;
    top:0;
    right:0;
    font-size:12px;
}

.grphsub .checkboxc{ margin-right:0}
.grphsub .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.grphsub .ng-select .ng-select-container{
    padding:4px 2px 4px 10px;
}

.grphsub .ng-select .ng-select-container,
.grphsub .ng-select.ng-select-single .ng-select-container {
    min-height:24px;
    height:24px;
}

.grphsub .ng-select.ng-select-single .ng-select-container {min-width:80px;}

.grphsub .ng-select .ng-select-container{
    font-size:11px;
}

.overview-card-wrapper .graphhead .overview-title {
    margin-right:78px;
    padding-right:5px;
}

.overview-card-wrapper .graphhead .overview-title.by_proj {
    margin-right:65px;
}

