@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../../assets/fonts/OpenSans/OpenSans-Bold.eot') format('eot'), url('../../assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'), url('../../assets/fonts/OpenSans/OpenSans-Bold.svg') format('svg'), url('../../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../../assets/fonts/OpenSans/OpenSans-Regular.eot') format('eot'), url('../../assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'), url('../../assets/fonts/OpenSans/OpenSans-Regular.svg') format('svg'), url('../../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff'), /*  Modern Browsers */ url('../../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    /* Safari, Android, iOS */
} 

@font-face {
    font-family: 'OpenSans-ExtraBold';
  src: url('../../assets/fonts/OpenSans/OpenSans-ExtraBold.eot') format('eot'), url('../../assets/fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'), url('../../assets/fonts/OpenSans/OpenSans-ExtraBold.svg') format('svg'), url('../../assets/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'OpenSans-Light';
  src: url('../../assets/fonts/OpenSans/OpenSans-Light.eot') format('eot'), url('../../assets/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'), url('../../assets/fonts/OpenSans/OpenSans-Light.svg') format('svg'), url('../../assets/fonts/OpenSans/OpenSans-Light.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'OpenSans-SemiBold';
  src: url('../../assets/fonts/OpenSans/OpenSans-Semibold.eot') format('eot'), url('../../assets/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'), url('../../assets/fonts/OpenSans/OpenSans-Semibold.svg') format('svg'), url('../../assets/fonts/OpenSans/OpenSans-Semibold.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-Black';
  src: url('../../assets/fonts/Raleway/raleway-black.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-black.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-black.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-black.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-black.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-Bold';
  src: url('../../assets/fonts/Raleway/raleway-bold.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-bold.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-bold.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-bold.woff') format('woff'), /*  Modern Browsers */ url('../../assets/fonts/Raleway/raleway-bold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-ExtraBold';
  src: url('../../assets/fonts/Raleway/raleway-extrabold.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-extrabold.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-extrabold.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-extrabold.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-extrabold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-ExtraLight';
  src: url('../../assets/fonts/Raleway/raleway-extralight.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-extralight.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-extralight.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-extralight.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-extralight.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-Light';
  src: url('../../assets/fonts/Raleway/raleway-light.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-light.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-light.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-light.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-light.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-Medium';
  src: url('../../assets/fonts/Raleway/raleway-medium.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-medium.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-medium.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-medium.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-medium.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-Regular';
  src: url('../../assets/fonts/Raleway/raleway-regular.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-regular.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-regular.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-regular.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-regular.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway-SemiBold';
  src: url('../../assets/fonts/Raleway/raleway-semibold.eot') format('eot'), url('../../assets/fonts/Raleway/raleway-semibold.woff2') format('woff2'), url('../../assets/fonts/Raleway/raleway-semibold.svg') format('svg'), url('../../assets/fonts/Raleway/raleway-semibold.woff') format('woff'), /* Modern Browsers */ url('../../assets/fonts/Raleway/raleway-semibold.ttf') format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Rupee-Font';
  src: url('../../assets/fonts/Rupee/IndianRupee.ttf') format('truetype');
}


@font-face {
    font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon/icomoon.eot?3unyk5');
  src: url('../../assets/fonts/icomoon/icomoon.eot?3unyk5#iefix') format('embedded-opentype'), url('../../assets/fonts/icomoon/icomoon.ttf?3unyk5') format('truetype'), url('../../assets/fonts/icomoon/icomoon.woff?3unyk5') format('woff'), url('../../assets/fonts/icomoon/icomoon.svg?3unyk5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
    content: "\e900";
}

.icon-adv-settings:before {
    content: "\e901";
    color: #3c4558;
}

.icon-arrow-up:before {
    content: "\e902";
    color: #3c4558;
}

.icon-bulb:before {
    content: "\e903";
    color: #6874f1;
}

.icon-calendar:before {
    content: "\e904";
    color: #3c4558;
}

.icon-chain .path1:before {
    content: "\e905";
    color: #ffffff;
    opacity: 0.3;
}

.icon-chain .path2:before {
    content: "\e906";
    margin-left: -1.5em;
    color: #3c4558;
    opacity: 0.8;
}

.icon-copy:before {
    content: "\e907";
    color: #3c4558;
}

.icon-cube:before {
    content: "\e908";
    color: #6874f1;
}

.icon-data-sources:before {
    content: "\e909";
    color: #6874f1;
}

.icon-disc:before {
    content: "\e90a";
    color: #6874f1;
}

.icon-done:before {
    content: "\e90b";
    color: #75cb98;
}

.icon-edit-icon:before {
    content: "\e90c";
    color: #6874f1;
}

.icon-equalizer:before {
    content: "\e90d";
    color: #3c4558;
}

.icon-file-html:before {
    content: "\e90e";
    color: #3c4558;
}

.icon-file-text:before {
    content: "\e90f";
    color: #3c4558;
}

.icon-hierarchy:before {
    content: "\e910";
    color: #6874f1;
}

.icon-link-edit .path1:before {
    content: "\e911";
    color: #000000;
}

.icon-link-edit .path2:before {
    content: "\e912";
    margin-left: -1em;
    color: #ffffff;
}

.icon-link-edit .path3:before {
    content: "\e913";
    margin-left: -1em;
    color: #3c4558;
    opacity: 0.797;
}

.icon-list:before {
    content: "\e914";
}

.icon-menu-1:before {
    content: "\e915";
    color: #3c4558;
}

.icon-menu-3:before {
    content: "\e916";
}

.icon-menu:before {
    content: "\e917";
    color: #f0f5fa;
}

.icon-models:before {
    content: "\e918";
    color: #6874f1;
}

.icon-padlock:before {
    content: "\e919";
    color: #6874f1;
}

.icon-processing:before {
    content: "\e91a";
    color: #6874f1;
}

.icon-projects:before {
    content: "\e91b";
    color: #6874f1;
}

.icon-refresh:before {
    content: "\e91c";
    color: #3c4558;
}

.icon-reload:before {
    content: "\e91d";
    color: #6874f1;
}

.icon-search:before {
    content: "\e91e";
    color: #3c4558;
}

.icon-settings:before {
    content: "\e91f";
    color: #3c4558;
}

.icon-star:before {
    content: "\e920";
    color: #f5811f;
}

.icon-three-dots:before {
    content: "\e921";
}

.icon-time .path1:before {
    content: "\e922";
    color: #e5e7eb;
}

.icon-time .path2:before {
    content: "\e923";
    margin-left: -1em;
    color: none;
}

.icon-time .path3:before {
    content: "\e924";
    margin-left: -1em;
    color: #3c4558;
}

.icon-tools:before {
    content: "\e925";
    color: #6874f1;
}

.icon-trash:before {
    content: "\e926";
    color: #3c4558;
}

.icon-user:before {
    content: "\e927";
    color: #6874f1;
}

.icon-warn:before {
    content: "\e928";
    color: #feab3c;
}

.icon-warning .path1:before {
    content: "\e929";
    color: #f15752;
}

.icon-warning .path2:before {
    content: "\e92a";
    margin-left: -1em;
    color: #ffffff;
}

.icon-warning .path3:before {
    content: "\e92b";
    margin-left: -1em;
    color: #ffffff;
}

.icon-warning .path4:before {
    content: "\e92c";
    margin-left: -1em;
    color: #ffffff;
}
