/* common theme */
$teal :#006e74;
$blue :#3a97d3;
$red: #ec2024;
$grey:#989898;
$pink:#FFC0CB;
$white:#fff;
$yellow: #FFFF00;
$black:#000;
$orange :#f39c12;

/* theme  color code */    
$bg-clr:#ecece1;
$base-clr: #006e74;
$leftnav_clr:#006e74;
$reverse-clr: #fff;
$reversehvr-clr:#ecece1;
$secondry-clr:#0097ab;
$lightdark-clr:#c2bcbe;
$hover-clr:#004448;
$text-clr:#231f20;
$text-med-clr:#777;
$text-medh-clr:#4c4c4c;
$slider-bg:#ccc;
$link-clr:#006e74;
$link-hvr:#004448;
$fadebase-clr:#006e74;
$disable-clr:#a6b8b9;
$disable-btn:#d2dada;
$disable-btn-clr:#a6b8b9;
$even-clr:#f5f5e9; 
$odd-clr:#fff; 
$td_head:#006e74;
$tdactive-clr:#D7E0E3;
$tdactive-txt:#C2BCB3;
$td-bdr:#c2bcbe;
$td-txt:#505050;
$tab-bg:#f7f7f7;
$close-btn:#777;
$close-btnhvr:#000; 
$footer-txt: #807e7e;
$gradient-bg:linear-gradient(#006e74 , #109aa2); 
$input-bg:#fbfbfb; /* input field background */
$input-txt:#231f20; /* input text color */
$input-bdr:#c2bcbe; /* input field border */
$input-bdrerror :#a94442; /* input field error */
$input_disbg: #e4e4e4;
$input-disbdr: #d4d4d4;
$input-disclr:#929292;
$errormgclr:#a94442 ;
$steptab-bdr:#c2bcbe;
$btn_clr:#006e74;
$btn_hvr:#045459;
$grey_btn:#7a7480;
$grey_btn_hvr: #969696;
$grey-btn_dis:#afafaf;
$grey_btnt:#fff;
$grey-btnt_dis:#ccc;
$focus-txt:#006e74;
$tab_bdr: #eee;
$teal_light:#01B27C;

$primary-clr: #3A97D3;  /* default ** pending ** normal ** primary*/
$info-clr: #00c0ef; /* for progress ** running ** active * reopened ** info */
$success-clr : #5ca95c; /* for completed ** approved ** success */
$warning-clr :#f39c12; /* for completed ** approved ** success */
$danger-clr : #f56954; /* error ** danger */
$mute-clr : #b5bbc8; /* stopped ** mute */
$progressbar-bg: #9b9b9b; /* backgrond color of progress bar */

/* scroll bar related */
$scrollbar_shadow_color: #c3c3d8;
$scrollbar_highlight_color:#c3c3d8;
$scrollbar_darkshadow_color: #c3c3d8;
$scrollbar_track_color: #c3c3d8;
$scrollbar_background: #c3c3d8;
$scrollbar_border: 0 none #d5d5da;
$scrollbar_active: #c3c3d8;
$scrollbar_hover : #c3c3d8;


.adjusticons {
    .ico-wt-txt.logoc span {
        background-image: url("assets/images/qubzn-text-t.png");
    }
}  

.qubzimg_footer{background-image: url("assets/images/qubzn-text-t.png");}

.ico-wtno-txt span {
    background-image: url("assets/images/qubzn-icon-t.png");
}

.licenceico span{
    background-image: url("assets/images/qubzn-text-wf.png");
}

.db_box:hover .modelimg{
    background-image: url("assets/images/model_ico64_w.png");
}

.ingestimg,
a:not([href]):not([tabindex]) .db_box:hover .ingestimg {
    background-image: url("assets/images/qubzn-icon-t.png");
} 

.db_box:hover .ingestimg{
    background-image: url("assets/images/qubzn-logo-bl.png");
}

/* 
.modelimg,
a:not([href]):not([tabindex]) .db_box:hover .modelimg {
    background-image: url("assets/images/model_ico64_t.png");
} */

.modelsico {
    background-image: url("assets/images/model_ico21_t.png");
} 

.sidebar-list a:hover .modelsico,
.active.sidebar-list a .modelsico,
.model_licon{
    background-image: url("assets/images/model_ico21_t.png");
}

/* */

.dataexpimg,
a:not([href]):not([tabindex]) .db_box:hover .dataexpimg {
    background-image:url("assets/images/data_explorer_icon_t.png");
} 

.active .dataexpimg{
    background-image:url("assets/images/data_explorer_icon_br.png");
} 

.dataexpico  {
    background-image:url("assets/images/data_explorer_icon_t.png");
} 

.sidebar-list a:hover .dataexpico ,
.sidebar-list a:focus .dataexpico ,
.active.sidebar-list a .dataexpico {
    background-image:url("assets/images/data_explorer_icon_t.png");
} 

.adminbox{
    .um-img{background-image: url("assets/images/userManagement_t.png");}
    .mvs-img{background-image: url("assets/images/virtualSources_b.png");}
    .daa-img{background-image: url("assets/images/dataAccess_b.png");}
    .sc-img{background-image: url("assets/images/serverConfig_t.png");}
}

a.boxlink:hover {
    .um-img{background-image: url("assets/images/userManagement_tw.png");}
    .mvs-img{background-image: url("assets/images/virtualSources_w.png");}
    .daa-img{background-image: url("assets/images/dataAccess_bw.png");}
    .sc-img{background-image: url("assets/images/serverConfig_tw.png");}
}

.slim-loading-bar-progress,
.spinner-border{
    background-image: url("assets/images/QubzLoad_t.gif");
}

.active .model_b{
    background-image: url("assets/images/modelico_bc_15X15_g.png");
} 

.model_b{
    background-image: url("assets/images/modelico_bc_15X15_b.png");
} 

/* *********************** blue  color code ********************* */  

$b_bg-clr:#eaf4f9;
$b_base-clr: #3A97D3;
$b_leftnav_clr:#3A97D3;
$b_reverse-clr: #fff;
$b_reversehvr-clr:#e9f6ff;
$b_secondry-clr: #ccc;
$b_lightdark-clr: #E0E4ED;
$b_hover-clr:#1e84c5;
$b_text-clr:#333;
$b_text-med-clr:#777;
$b_text-medh-clr:#4c4c4c;
$b_slider-bg:#ccc;
$b_link-clr:#3A97D3;
$b_link-hvr:#1e84c5;
$b_fadebase-clr:#4eabe6;
$b_disable-clr:#9ed7fb;
$b_disable-btn:#accce0;
$b_disable-btn-clr:#f0f4f7;
$b_even-clr:#f5fafe; 
$b_odd-clr:#fff; 
$b_td_head:#3A97D3;
$b_tdactive-clr:#d4ebfd;
$b_tdactive-txt:#333;
$b_td-bdr:#eaeaea;
$b_td-txt:#505050;
$b_tab-bg:#f7f7f7;
$b_close-btn:#777;
$b_close-btnhvr:#000; 
$b_footer-txt: #807e7e;
$b_gradient-bg:linear-gradient(#3A97D3 , #1877B5); 
$b_input-bg:#fbfbfb; /* input field background */
$b_input-txt:#333; /* input text color */
$b_input-bdr:#ccc; /* input field border */
$b_input-bdrerror :#a94442; /* input field error */
$b_input_disbg: #e4e4e4;
$b_input-disbdr: #d4d4d4;
$b_input-disclr:#929292;
$b_errormgclr:#a94442 ;
$b_steptab-bdr:#dedede;
$b_btn_clr:#3A97D3;
$b_btn_hvr:#3A97D3;
$b_grey_btn:#7a7480;
$b_grey_btn_hvr: #969696;
$b_grey-btn_dis:#afafaf;
$b_grey_btnt:#fff;
$b_grey-btnt_dis:#ccc;
$b_focus-txt:#00558c;
$b_tab_bdr: #eee;

$b_primary-clr: #3A97D3;  /* default ** pending ** normal ** primary*/
$b_info-clr: #00c0ef; /* for progress ** running ** active * reopened ** info */
$b_success-clr : #5ca95c; /* for completed ** approved ** success */
$b_warning-clr :#f39c12; /* for completed ** approved ** success */
$b_danger-clr : #f56954; /* error ** danger */
$b_mute-clr : #b5bbc8; /* stopped ** mute */
$b_progressbar-bg: #9b9b9b; /* backgrond color of progress bar */

/* scroll bar related */
$b_scrollbar_shadow_color: #c3c3d8;
$b_scrollbar_highlight_color:#c3c3d8;
$b_scrollbar_darkshadow_color: #c3c3d8;
$b_scrollbar_track_color: #c3c3d8;
$b_scrollbar_background: #c3c3d8;
$b_scrollbar_border: 0 none #d5d5da;
$b_scrollbar_active: #c3c3d8;
$b_scrollbar_hover : #c3c3d8;

/* */
.blue_theme{
    .adjusticons {
        .ico-wt-txt.logoc span {
            background-image: url("assets/images/qubzn-text-b.png");
        }
    }  

    .qubzimg_footer{background-image: url("assets/images/qubzn-text-b.png");}

    .ico-wtno-txt span {
        background-image: url("assets/images/qubzn-icon-b.png");
    }

    .licenceico span{
        background-image: url("assets/images/qubzn-text-wf.png");
    }

    .db_box:hover .modelimg{
        background-image: url("assets/images/model_ico64_w.png");
    }

    .modelimg,
    a:not([href]):not([tabindex]) .db_box:hover .modelimg {
        background-image: url("assets/images/qubzn-icon-b.png");
    } 

    .db_box:hover .ingestimg{
        background-image: url("assets/images/qubzn-logo-bl.png");
    }

    .ingestimg,
    a:not([href]):not([tabindex]) .db_box:hover .ingestimg {
        background-image: url("assets/images/qubzn-logo-bl.png");
    } 

    .modelsico {
        background-image: url("assets/images/model_ico21.png");
    } 

    .sidebar-list a:hover .modelsico,
    .active.sidebar-list a .modelsico,
    .model_licon{
        background-image: url("assets/images/model_ico21.png");
    }

    .dataexpimg,
    a:not([href]):not([tabindex]) .db_box:hover .dataexpimg {
        background-image:url("assets/images/data_explorer_icon_b.png");
    } 

    .active .dataexpimg{
        background-image:url("assets/images/data_explorer_icon_br.png");
    } 

    .dataexpico  {
        background-image:url("assets/images/data_explorer_icon_b.png");
    } 

    .sidebar-list a:hover .dataexpico ,
    .sidebar-list a:focus .dataexpico ,
    .active.sidebar-list a .dataexpico {
        background-image:url("assets/images/data_explorer_icon_b.png");
    } 

    .adminbox{
        .um-img{background-image: url("assets/images/userManagement_b.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_b.png");}
        .daa-img{background-image: url("assets/images/dataAccess_b.png");}
        .sc-img{background-image: url("assets/images/serverConfig_b.png");}
    }

    a.boxlink:hover {
        .um-img{background-image: url("assets/images/userManagement_bw.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_w.png");}
        .daa-img{background-image: url("assets/images/dataAccess_bw.png");}
        .sc-img{background-image: url("assets/images/serverConfig_bw.png");}
    }

    .slim-loading-bar-progress,
    .spinner-border{
        background-image: url("assets/images/QubzLoad_b.gif");
    }

    .active .model_b{
        background-image: url("assets/images/modelico_bc_15X15_g.png");
    } 

    .model_b{
        background-image: url("assets/images/modelico_bc_15X15_b.png");
    } 
}

/* ******************* night mode **************** */


$g_bg-clr:#B5B5B5;
$g_base-clr: #19191d;
$g_leftnav_clr:#fff;
$g_reverse-clr: #25252A;
$g_reversehvr-clr:#fff;
$g_secondry-clr: #ccc;
$g_lightdark-clr: #3A3A3A;
$g_hover-clr:#fdb913;
$g_text-clr:#fff;
$g_text-med-clr:#777;
$g_text-medh-clr:#eee;
$g_slider-bg:#ccc;
$g_link-clr:#fdb913;
$g_link-hvr:#fdb913;
$g_fadebase-clr:#ccc;
$g_disable-clr:#777;
$g_disable-btn:#777;
$g_disable-btn-clr:#f0f4f7;
$g_even-clr:#818181; 
$g_odd-clr:#636262; 
$g_eo_bdr:#989898;
$g_td_head:#BEBEBE;
$g_tdactive-clr:#404040;
$g_tdactive-txt:#f8f9cf;
$g_td-bdr:#3A3A3A;
$g_td-txt:#fff;
$g_tab-bg:#25252a;
$g_close-btn:#fff;
$g_close-btnhvr:#f7f7f7; 
$g_footer-txt: #333;
$g_gradient-bg:linear-gradient(#909090 , #25252A); 
$g_input-bg:#efefef; /* input field background */
$g_input-txt:#333; /* input text color */
$g_input-bdr:#e4e4e4; /* input field border */
$g_input-bdrerror :#fb7776; /* input field error */
$g_input_disbg: #e4e4e4;
$g_input-disbdr: #d4d4d4;
$g_input-disclr:#929292;
$g_errormgclr:#fb7776;
$g_steptab-bdr:#dedede;
$g_btn_clr:#636262;
$g_btn_hvr: #3a3a3a;
$g_btn_dis:#ccc;
$g_btn_dist:#ababab;
$g_grey_btn:#7a7480;
$g_grey_btn_hvr: #fdb913;
$g_grey-btn_dis:#afafaf;
$g_grey_btnt:#fff;
$g_grey-btnt_dis:#ccc;
$g_focus-txt:#fde29f;
$g_tab_bdr: #3A3A3A;


$g_primary-clr: #a2d9fd;  /* default ** pending ** normal ** primary*/
$g_info-clr: #00c0ef; /* for progress ** running ** active * reopened ** info */
$g_success-clr : #b0e8b0; /* for completed ** approved ** success */
$g_warning-clr :#f7b958; /* for completed ** approved ** success */
$g_danger-clr : #fba396; /* error ** danger */
$g_mute-clr : #afafaf; /* stopped ** mute */
$g_progressbar-bg: #9b9b9b; /* backgrond color of progress bar */

/* scroll bar related */
$g_scrollbar_shadow_color: #c3c3d8;
$g_scrollbar_highlight_color:#c3c3d8;
$g_scrollbar_darkshadow_color: #c3c3d8;
$g_scrollbar_track_color: #c3c3d8;
$g_scrollbar_background: #c3c3d8;
$g_scrollbar_border: 0 none #d5d5da;
$g_scrollbar_active: #c3c3d8;
$g_scrollbar_hover : #c3c3d8;

/* only for grey theme */
$g_yellow_txt:#fdb913;
$g_cardcount_txt:#fff;
$g_bdr_clr:#3A3A3A;
$g_white-text:#fff;
$g_bcrum_btn_bg:#636262;
$g_bcrum_btn_bdr: #B3B3B3;
$g_bdr_light:#777;
$g_graph_bg:#fff;

.grey_theme{
    .adjusticons {
        .ico-wt-txt.logoc span {
            background-image: url("assets/images/qubzn-text-w.png");
        }
    }  
    
    .qubzimg_footer{background-image: url("assets/images/qubzn-text-w.png");}
    

    .ico-wtno-txt span {
        background-image: url("assets/images/qubzn-logo-bl.png");
    }

    .licenceico span{
        background-image: url("assets/images/qubzn-text-wf.png");
    }

    .db_box:hover .modelimg{
        background-image: url("assets/images/model_ico64_g.png");
    }

    .modelimg,
    a:not([href]):not([tabindex]) .db_box:hover .modelimg,
    .modelsico {
        background-image: url("assets/images/model_ico64_y.png");
    } 

    
    .ingestimg,
    a:not([href]):not([tabindex]) .db_box:hover .ingestimg {
        background-image: url("assets/images/qubzn-logo-bl.png");
    } 

    .db_box:hover .ingestimg{
        background-image: url("assets/images/qubzn-logo-bl.png");
    }

    .modelsico  {
        background-image: url("assets/images/model_ico21_w.png");
    } 

    .sidebar-list a:hover .modelsico ,
    .sidebar-list a:focus .modelsico ,
    .active.sidebar-list a .modelsico,
    .model_licon{
        background-image: url("assets/images/model_ico21_y.png");
    } 

    /* */

    .dataexpimg,
    a:not([href]):not([tabindex]) .db_box:hover .dataexpimg {
        background-image:url("assets/images/data_explorer_icon_ga.png");
    } 

    .active .dataexpimg{
        background-image:url("assets/images/data_explorer_icon_g.png");
    } 

    .dataexpico  {
        background-image:url("assets/images/data_explorer_icon_g.png");
    } 

    .sidebar-list a:hover .dataexpico ,
    .sidebar-list a:focus .dataexpico ,
    .active.sidebar-list a .dataexpico {
        background-image:url("assets/images/data_explorer_icon_ga.png");
    } 
    
    .adminbox{
        .um-img{background-image: url("assets/images/userManagement_y.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_b.png");}
        .daa-img{background-image: url("assets/images/dataAccess_y.png");}
        .sc-img{background-image: url("assets/images/serverConfig_y.png");}
    }

    a.boxlink:hover {
        .um-img{background-image: url("assets/images/userManagem_gy.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_w.png");}
        .daa-img{background-image: url("assets/images/dataAccess_bw.png");}
        .sc-img{background-image: url("assets/images/serverConfig_g.png");}
    }

    .slim-loading-bar-progress,
    .spinner-border{
        background-image: url("assets/images/QubzLoad.gif");
    }

    .active .model_b{
        background-image: url("assets/images/modelico_bc_15X15_g.png"); 
    } 
    
    .model_b{
        background-image: url("assets/images/modelico_bc_15X15_y.png");
    }
}



/* ******************** red theme *************** */

$r_bg-clr:#fff8f8;
$r_base-clr: #f74d4d;
$r_leftnav_clr:#626363;
$r_reverse-clr: #fff;
$r_reversehvr-clr:#ffe6e6;
$r_secondry-clr: #ccc;
$r_lightdark-clr: #e6d7d7;
$r_hover-clr:#ef3738;
$r_text-clr:#333;
$r_text-med-clr:#777;
$r_text-medh-clr:#4c4c4c;
$r_slider-bg:#ccc;
$r_link-clr:#ef3738;
$r_link-hvr:#d82a2b;
$r_fadebase-clr:#d65e60;
$r_disable-clr:#e8acad;
$r_disable-btn:#f3bebf;
$r_disable-btn-clr:#f0f4f7;
$r_even-clr:#fffbfb; 
$r_odd-clr:#fff; 
$r_td_head:#fd7d7e;
$r_tdactive-clr:#FEE8E8;
$r_tdactive-txt:#333;
$r_td-bdr:#eaeaea;
$r_td-txt:#505050;
$r_tab-bg:#fff8f8;
$r_close-btn:#777;
$r_close-btnhvr:#000; 
$r_footer-txt: #807e7e;
$r_gradient-bg:linear-gradient(#f05253 , #ea292a); 
$r_input-bg:#fffefe; /* input field background */
$r_input-txt:#333; /* input text color */
$r_input-bdr:#ccc; /* input field border */
$r_input-bdrerror :#a94442; /* input field error */
$r_input_disbg: #ece4e4;
$r_input-disbdr: #d8d5d5;
$r_input-disclr:#d4afb0;
$r_errormgclr:#a94442 ;
$r_steptab-bdr:#dedede;
$r_btn_clr:#fd7d7e;
$r_btn_hvr:#f74d4d;
$r_grey_btn:#7a7480;
$r_grey_btn_hvr: #969696;
$r_grey-btn_dis:#afafaf;
$r_grey_btnt:#fff;
$r_grey-btnt_dis:#ccc;
$r_focus-txt:#d80707;
$r_tab_bdr: #eee;

$r_primary-clr: #3A97D3;  /* default ** pending ** normal ** primary*/
$r_info-clr: #00c0ef; /* for progress ** running ** active * reopened ** info */
$r_success-clr : #5ca95c; /* for completed ** approved ** success */
$r_warning-clr :#f39c12; /* for completed ** approved ** success */
$r_danger-clr : #f56954; /* error ** danger */
$r_mute-clr : #b5bbc8; /* stopped ** mute */
$r_progressbar-bg: #9b9b9b; /* backgrond color of progress bar */

/* scroll bar related */
$r_scrollbar_shadow_color: #c3c3d8;
$r_scrollbar_highlight_color:#c3c3d8;
$r_scrollbar_darkshadow_color: #c3c3d8;
$r_scrollbar_track_color: #c3c3d8;
$r_scrollbar_background: #c3c3d8;
$r_scrollbar_border: 0 none #d5d5da;
$r_scrollbar_active: #c3c3d8;
$r_scrollbar_hover : #c3c3d8;

.red_theme{
    .adjusticons {
        .ico-wt-txt.logoc span {
            background-image: url("assets/images/qubzn-text-r.png");
        }
    }   

    .qubzimg_footer{background-image: url("assets/images/qubzn-text-r.png");}

    .ico-wtno-txt span {
        background-image: url("assets/images/qubzn-icon-r.png");
    }

    .licenceico span{
        background-image: url("assets/images/qubzn-text-wf.png");
    }
 
    .db_box:hover .modelimg{
        background-image: url("assets/images/model_ico64_rw.png");
    }

    .modelimg,
    a:not([href]):not([tabindex]) .db_box:hover .modelimg,
    .modelsico {
        background-image: url("assets/images/model_ico64_red.png");
    } 

    .ingestimg,
    a:not([href]):not([tabindex]) .db_box:hover .ingestimg {
        background-image: url("assets/images/qubzn-icon-r.png");
    } 

    .db_box:hover .ingestimg{
        background-image: url("assets/images/qubzn-logo-bl.png");
    }

    .modelsico  {
        background-image: url("assets/images/model_ico21_g.png");
    } 

    .sidebar-list a:hover .modelsico ,
    .sidebar-list a:focus .modelsico ,
    .active.sidebar-list a .modelsico,
    .model_licon{
        background-image: url("assets/images/model_ico21_r.png");
    } 

    /* */

    .dataexpimg,
    a:not([href]):not([tabindex]) .db_box:hover .dataexpimg {
        background-image:url("assets/images/data_explorer_icon_ra.png");
    } 

    .active .dataexpimg{
        background-image:url("assets/images/data_explorer_icon_br.png");
    }

    .dataexpico  {
        background-image:url("assets/images/data_explorer_icon_rn.png");
    } 

    .sidebar-list a:hover .dataexpico ,
    .sidebar-list a:focus .dataexpico ,
    .active.sidebar-list a .dataexpico {
        background-image:url("assets/images/data_explorer_icon_ra.png");
    } 

    .adminbox{
        .um-img{background-image: url("assets/images/userManagement_r.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_r.png");}
        .daa-img{background-image: url("assets/images/dataAccess_r.png");}
        .sc-img{background-image: url("assets/images/serverConfig_r.png");}
    }

    a.boxlink:hover {
        .um-img{background-image: url("assets/images/userManagement_rw.png");}
        .mvs-img{background-image: url("assets/images/virtualSources_w.png");}
        .daa-img{background-image: url("assets/images/dataAccess_bw.png");}
        .sc-img{background-image: url("assets/images/serverConfig_rw.png");}
    }

    .slim-loading-bar-progress,
    .spinner-border{
        background-image: url("assets/images/QubzLoad_r.gif");
    }
    .active .model_b{
        background-image: url("assets/images/modelico_bc_15X15_g.png");
    } 
    
    .model_b{
        background-image: url("assets/images/modelico_bc_15X15_r.png");
    }
}
/* *********************************** */