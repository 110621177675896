.primary-active {
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 1, 0.25);
    background-image: linear-gradient(358deg, #5e00ff, #5f98ec);
    color: #ffffff;
}

.primary-active::after {
    background-image: url('../../assets/images/arrow-right.svg');
    background-color: rgba(0, 0, 0, 0.1);
}

.secondary1 {
    background-color: #ffffff;
    color: #6874f1;
}

.secondary1::after {
    background-image: url('../../assets/images/settings.svg');
}

.primary-inactive,
.primary-active,
.secondary1,
.mr-btn-normal,
.secondary2 {
    border: 1px solid rgba(104, 116, 241, 0.2);
    cursor: pointer;
    font-family: OpenSans-SemiBold, sans-serif;
    font-size: 12px;
    border-radius: 25px;
    min-width: 120px;
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 1px 12px;
    margin: 0 3px;
}

.primary-inactive::after,
.primary-active::after,
.secondary1::after,
.mr-btn-normal::after,
.secondary2::after {
    display: inline-block;
    text-align: center;
    content: '';
    background-repeat: no-repeat;
    height: 36px;
    width: 36px;
    background-size: 16px;
    background-position: center;
    border-radius: 50%;
    margin-left: 10px;
}

.primary-active {
    border-top: none;
}

.primary-inactive {
    background-color: rgba(60, 69, 88, 0.4);
    background-image: none;
    box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14);
    border: none;
    color: #ffffff;
    cursor: not-allowed;
}

.primary-inactive::after {
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url('../../assets/images/arrow-right.svg');
}

.mr-btn-normal {
    background-color: #ffffff;
    color: #6874f1;
    padding-right: 12px;
    -ms-flex-pack: center;
    justify-content: center;
    height: 34px;
}

.mr-btn-normal::after {
    display: none;
}

.secondary2 {
    background-color: #ffffff;
    padding-bottom: 0;
}

.secondary2::after {
    content: '+';
    color: #ffffff;
    background-size: auto;
    font-size: 25px;
}

.tertiary-active,
.normal-btn ,
.grey-btn ,
.md-drppicker .btn {
    border-radius: 5px;
    border: 1px solid rgba(60, 69, 88, 0.1);
    font-family: OpenSans-Semibold, sans-serif;
    font-size: 13px;
    color: #ffffff;
    padding: 7px 12px;
    margin: 0 3px;
    min-width: 80px;
    text-align: center;
}

.md-drppicker .clear{
    border:1px solid #9a9a9a;
    background:#aaa;
    color:#fff;
}

.md-drppicker .clear:hover{
    background:#969696;
}

/* overwrite date picker ranger styles */
.md-drppicker .btn{
    border-radius: 5px !important;
    color: #ffffff !important;
    padding: 9px 12px !important;
    line-height:1 !important;
    border: 1px solid rgba(60, 69, 88, 0.1) !important;
}

.md-drppicker .btn.clear {
    border: 1px solid #9a9a9a !important;
    background: #aaa !important;
    color: #fff !important;
}

.md-drppicker .clear:hover{
    background:#969696 !important;
}

.md-drppicker .clear svg{
    display:none !important;
}

.tertiary-inactive {
    background-color: rgba(60, 69, 88, 0.3);
    cursor: not-allowed;
    border-radius: 5px;
    border: 1px solid rgba(60, 69, 88, 0.1);
    font-family: OpenSans-Semibold, sans-serif;
    font-size: 13px;
    color: #ffffff;
    padding: 7px 12px;
    margin: 0 3px;
    min-width: 80px;
    text-align: center;
}

.tertiary-inactive:last-child,
.normal-btn:last-child{
    margin-right:0;
}

.input-field,
.search-bar {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
    color: #9b9b9b;
    border-radius: 4px;
    background-color: #f7f7f7;
    border: none;
    padding: 9px 15px;
}

.search-bar {
    padding-right: 30px;
}

.search-bar-icon {
    margin-left: -25px;
}

.select-box,
.date-pick {
    border-radius: 4px;
    display: inline-block;
}

.select-box-dropdown div {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
    color: #9b9b9b;
    padding: 8px 12px;
    padding-right:26px;
}

.select-box-dropdown div::after {
    content: '';
    display: inline-block;
    background-image: url('../../assets/images/down-arrow.svg');
    background-repeat: no-repeat;
    border: 0;
    float:right;
    margin: 5px 0 0 10px;
    height: 7px;
    width: 10px;
}

.select-box .dropdown-menu {
    margin: 0;
    padding: 0;
}

.select-box .dropdown-menu li {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
    padding: 7px 10px;
    line-height: 1.67;
    color: #000000;
    cursor: context-menu;
}

.select-box .dropdown-menu li:hover {
    background-color: rgba(95, 152, 236, 0.4);
    color: #ffffff;
}

.date-pick {
    position: relative;
}

.date-pick input {
    border: none;
    color: #9b9b9b;
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
    outline: none;
    padding: 8px 20px 8px 12px;
}

.date-pick::after {
    content: '';
    cursor: pointer;
    display: inline-block;
    background-image: url('../../assets/images/calendar.svg');
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 10px;
    top: 9px;
}

.form-field {
    display: -ms-flexbox;
    display: flex;
}

.form-field .select-box {
    border-radius: 0;
}

.form-field .select-box,
.form-field input,
.form-field button {
    margin: 0;
}

.form-field input {
    border-radius: 4px 0 0 4px;
}

.form-field button {
    border-radius: 0 4px 4px 0;
}

.form-field-bar-active,
.form-field-bar-inactive {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
    border-radius: 4px;
    padding: 9px 15px;
}

.form-field-bar-active .mr-count-btch,
.form-field-bar-inactive .mr-count-btch {
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;
    text-align: center;
    margin-right: 8px;
    font-size: 13px;
}

.form-field-bar-active {
    background-color: #ffffff;
    border: 1px solid rgba(104, 116, 241, 0.3);
    box-shadow: 0 1px 4px 0 rgba(72, 80, 164, 0.1);
    color: #3c4558;
}

.form-field-bar-active .mr-count-btch {
    background-color: #6874f1;
    color: #ffffff;
}

.form-field-bar-inactive {
    background-color: inherit;
    border: 1px dotted rgba(104, 116, 241, 0.3);
    color: #9b9b9b;
}

.form-field-bar-inactive .mr-count-btch {
    background-color: #f7f7f7;
    color: #6874f1;
}

.cm-table {
    border-radius: 8px;
    border: 1px solid rgba(60, 69, 88, 0.1);
    color: #3c4558;
    font-size: 12px;
    width: 100%;
}

.cm-table table {
    margin: 0;
}

.cm-table .cm-table-head {
    background-color: #f7f7f7;
    border-radius: 8px 8px 0 0;
    font-family: OpenSans-SemiBold, sans-serif;
    border-bottom: none;
    height: 40px;
    vertical-align: middle;
}

.cm-table .cm-table-head th {
    border-bottom: 0;
}

.cm-table .cm-table-body {
    border-radius: 0 0 8px 8px;
    font-family: OpenSans-Regular, sans-serif;
}

.cm-table .cm-table-body tr {
    border-bottom: 1px solid rgba(60, 69, 88, 0.1);
}

.cm-table .cm-table-body tr:last-child {
    border-bottom: 0;
}

.cm-table th:first-child,
.cm-table td:first-child {
    padding-left: 20px;
}

.cm-mr-link {
    display: inline-block;
    font-family: OpenSans-SemiBold, sans-serif;
    font-size: 12px;
    color: #6874f1;
    cursor: context-menu;
}

.mr-flex-wrp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}

.icon-cont img {
    cursor: pointer;
}

.mr-rt-5 {
    margin-right: 15px;
}

.new-grp-cont {
    border: 1px solid rgba(60, 69, 88, 0.1);
    border-radius: 8px;
    margin-top: 27px;
}

.mr-cnt-blocks {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 12px 18px;
    width: 100%;
}

.rm-border {
    border-bottom: 0;
}

.card-comp-wrap {
    border-radius: 8px;
    box-shadow: -2px 4px 8px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: 176px;
}

.card-comp-wrap .card-comp-head {
    padding: 12px 0 20px 12px;
    border-radius: 8px 8px 0 0;
}

.card-comp-wrap .card-comp-head .header-txt {
    font-family: Raleway-SemiBold, sans-serif;
    font-size: 13px;
    line-height: 0.92;
    color: #3c4558;
    letter-spacing: 0.2px;
}

.card-comp-wrap .card-comp-body {
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    padding: 5px 8px 1px;
}

.card-comp-wrap .card-comp-body .card-comp-inner-wrp {
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: -15px;
}

.card-comp-wrap .card-comp-body .card-comp-inner-wrp .unorder-drop-dwn {
    border: 1px solid #f4f6f8;
    border-radius: 4px;
    margin-bottom: 6px;
}

.card-comp-wrap .card-comp-body .card-comp-inner-wrp .unorder-drop-dwn .select-li-txt {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 11px;
    line-height: 2;
    color: #3c4558;
    padding: 0 12px;
}

.card-comp-wrap .card-comp-body .card-comp-inner-wrp .unorder-drop-dwn .dropdwn-toggler {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 7px 12px;
}

.card-comp-wrap .card-comp-body .card-comp-inner-wrp .unorder-drop-dwn img {
    transition: all 0.2s ease-in;
}

.card-comp-wrap .select-fields-txt {
    font-family: Raleway-Medium, sans-serif;
    font-size: 11px;
    color: #9b9b9b;
    line-height: 2;
    padding: 0 12px;
}

.card-comp-wrap .done-btn {
    background-color: #828dff;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    font-family: Raleway-SemiBold, sans-serif;
    font-size: 12px;
    color: #ffffff;
    padding: 7px 12px;
    text-align: center;
    margin-top: 15px;
}

.bg-color-green {
    background-color: #9ae0ae;
}

.bg-color-blue {
    background-image: linear-gradient(to bottom, rgba(130, 141, 255, 0.8), #6989e8);
}

.mr-toggle {
    display: none;
}

.query-table-wrap {
    box-shadow: -2px 2px 10px 0 rgba(34, 75, 157, 0.14);
    border-radius: 8px;
    font-family: OpenSans-Regular, sans-serif;
    font-size: 12px;
}

.query-table-wrap .query-table-head {
    background-image: linear-gradient(347deg, #5e00ff, #5f98ec);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

.query-table-wrap .query-table-head .query-table-tabs li {
    cursor: pointer;
    padding: 10px 15px;
    color: #ffffff;
}

.query-table-wrap .query-table-head .query-table-tabs .active-tab {
    background-color: #828dff;
    font-family: OpenSans-SemiBold !important;
}

.query-table-wrap .query-table-body {
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    padding: 10px;
}

.rm-default-ul {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
}

.rotate-arrow {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.checkbox-container {
    font-family: OpenSans-Regular, sans-serif;
    font-size: 11px;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: #3c4558;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: #828dff;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1.4px solid rgba(60, 113, 230, 0.3);
}

.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container .checkmark:after {
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.table-wth-subfield-wrp {
    box-shadow: -4px 5px 16px 0 rgba(60, 69, 88, 0.1);
    border-radius: 8px;
}

.table-wth-subfield-wrp .table-wth-subfield-head {
    border-radius: 8px 8px 0 0;
    background-image: linear-gradient(to top, #5e00ff, #5f98ec);
    font-family: OpenSans-SemiBold, sans-serif;
    font-size: 12px;
    color: #ffffff;
    padding: 10px 20px;
}

.table-wth-subfield-wrp .table-wth-subfield-body {
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
}

.table-wth-subfield-wrp .table-wth-subfield-body .subfield-cont {
    padding: 10px 16px;
}

.table-wth-subfield-wrp .table-wth-subfield-body .subfield-cont:hover {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.table-wth-subfield-wrp .table-wth-subfield-body .subfield-cont img {
    cursor: pointer;
}

.table-wth-subfield-wrp .table-wth-subfield-body .subfield-cont .subfield-title {
    font-family: OpenSans-SemiBold, sans-serif;
    font-size: 11px;
    color: #3c4558;
}

.table-wth-subfield-wrp .table-wth-subfield-body .subfield-expanded {
    border-radius: 8px;
    background-color: #f7f7f7;
    min-height: 40px;
    margin-top: 8px;
    overflow: hidden;
}

.table-wth-subfield-wrp .inc-progress-bar-wrp {
    background-color: rgba(34, 75, 157, 0.14);
    height: 2px;
    width: 100%;
}

.table-wth-subfield-wrp .inc-progress-bar-wrp .inc-progress-val {
    background-color: #f15752;
    height: 100%;
    width: 50%;
}

.pd-full-10 {
    padding: 10px;
}

.cm-pagination {
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    display: inline-block;
}

.cm-pagination .mr-pagination-ul li {
    font-family: OpenSans-SemiBold, sans-serif;
    font-size: 11px;
    color: #3c4558;
    padding: 7px 10px;
}

.cm-pagination .mr-pagination-ul li:first-child,
.cm-pagination .mr-pagination-ul li:last-child {
    background-color: #828dff;
    cursor: pointer;
}

.cm-pagination .mr-pagination-ul li:first-child {
    border-radius: 4px 0 0 4px;
}

.cm-pagination .mr-pagination-ul li:last-child {
    border-radius: 0 4px 4px 0;
}

.cm-pagination .mr-pagination-ul li a {
    background-color: inherit;
    border: none;
    padding: 0;
}

.custom-arrw {
    border: solid #3c4558;
    border-width: 0 1.3px 1.3px 0;
    border-color: #ffffff;
    display: inline-block;
    padding: 3px;
}

.rotate-right {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.color-white {
    color: #ffffff !important;
}

.rotate-left {
    transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -webkit-transform: rotate(130deg);
}
